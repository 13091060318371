import React from 'react';
import { FifthSection, Header, Footer, Members, Navbar, SecondSection, ThirdSection } from '../../components/aboutSections';
import SvgSection from '../../components/aboutSections/svg';
import "./index.scss"


function About() {
    return (
        <div className="framer-body">
            <div id="main">
                <div className="framer-XyIhp framer-Pyo6Q framer-EjWs1 framer-jUJDS framer-8dioY framer-Adeeo" >
                    <div className="framer-1fdb8e9">
                        <Navbar />
                        <Header />
                        <SecondSection />
                        <ThirdSection />
                        <Members />
                        <FifthSection />
                        <Footer />
                    </div>
                </div>
            </div>
            <SvgSection />
            <div id="__framer-badge-container"></div>
        </div>
    );
}

export default About;