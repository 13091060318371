// counterSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { LanguageSliceType } from './interface';

const initialState: LanguageSliceType = {
    language: "en",
};

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            return {
                ...state,
                language: action.payload
            };
        }

    },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
