import React, { useEffect, useState } from 'react'
import "./index.scss"
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { RootState } from '../../../redux';
import { useSelector } from 'react-redux';
const textAnimation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: (custom: any) => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.2, duration: 1 },
    }),
};
const imgAnimation = {
    hidden: {
        opacity: 0,
    },
    visible: (custom: any) => ({
        opacity: 1,
        transition: { delay: custom * 0.5, duration: 3 },
    }),
};

const Header = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const [context, setContext] = useState({ title_highlight: "", title_top: "", title_bottom: "", text: "", button: "" });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);
    return (
        <section className="framer-1l9mm7q" >
            <div className="framer-1pvz644">
                <div className="ssr-variant hidden-p5w47l">
                    <div className="framer-y6bzdz-container" >
                        <div >

                            <div id="One"><canvas ></canvas></div>
                        </div>
                    </div>
                </div>
                <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                    <div className="framer-y6bzdz-container">
                        <div  >
                            <div id="One"><canvas></canvas></div>
                        </div>
                    </div>
                </div>

                <div className="framer-5j3a53" data-framer-component-type="SVG"  >
                    <motion.div custom={3} initial="hidden" animate="visible" variants={imgAnimation} className="svgContainer" >
                        <svg width="2072" height="1528" viewBox="0 0 2072 1528" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1776208339_28972">
                            <g opacity="0.7">
                                <g filter="url(#svg1776208339_28972_filter0_f_22_4879)">
                                    <ellipse cx="1646" cy="814.461" rx="176.199" ry="176.199" transform="rotate(180 1646 814.461)" fill="#7173FA" fillOpacity="0.1"></ellipse>
                                </g>
                                <mask id="svg1776208339_28972_mask0_22_4879" maskUnits="userSpaceOnUse" x="1220" y="100" width="852" height="1428">
                                    <rect x="1220" y="1527.43" width="1427.43" height="852" transform="rotate(-90 1220 1527.43)" fill="url(#svg1776208339_28972_paint0_radial_22_4879)"></rect>
                                </mask>
                                <g mask="url(#svg1776208339_28972_mask0_22_4879)">
                                    <rect x="1234.13" y="1343.8" width="117.466" height="117.466" transform="rotate(-90 1234.13 1343.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1234.13" y="1226.33" width="117.466" height="117.466" transform="rotate(-90 1234.13 1226.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1234.13" y="1108.87" width="117.466" height="117.466" transform="rotate(-90 1234.13 1108.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1234.13" y="991.403" width="117.466" height="117.466" transform="rotate(-90 1234.13 991.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1234.13" y="873.937" width="117.466" height="117.466" transform="rotate(-90 1234.13 873.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1234.13" y="756.471" width="117.466" height="117.466" transform="rotate(-90 1234.13 756.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1233.38" y="639.749" width="118.953" height="118.953" transform="rotate(-90 1233.38 639.749)" fill="white" fillOpacity="0.32"></rect>
                                    <rect x="1234.13" y="639.005" width="117.466" height="117.466" transform="rotate(-90 1234.13 639.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1234.13" y="521.539" width="117.466" height="117.466" transform="rotate(-90 1234.13 521.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1234.13" y="404.073" width="117.466" height="117.466" transform="rotate(-90 1234.13 404.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1351.59" y="1343.8" width="117.466" height="117.466" transform="rotate(-90 1351.59 1343.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1351.59" y="1226.33" width="117.466" height="117.466" transform="rotate(-90 1351.59 1226.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1351.59" y="1108.87" width="117.466" height="117.466" transform="rotate(-90 1351.59 1108.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1351.59" y="991.403" width="117.466" height="117.466" transform="rotate(-90 1351.59 991.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1351.59" y="873.937" width="117.466" height="117.466" transform="rotate(-90 1351.59 873.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1351.59" y="756.471" width="117.466" height="117.466" transform="rotate(-90 1351.59 756.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1351.59" y="639.005" width="117.466" height="117.466" transform="rotate(-90 1351.59 639.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1351.59" y="521.539" width="117.466" height="117.466" transform="rotate(-90 1351.59 521.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1351.59" y="404.073" width="117.466" height="117.466" transform="rotate(-90 1351.59 404.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1469.06" y="1343.8" width="117.466" height="117.466" transform="rotate(-90 1469.06 1343.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1469.06" y="1226.33" width="117.466" height="117.466" transform="rotate(-90 1469.06 1226.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1469.06" y="1108.87" width="117.466" height="117.466" transform="rotate(-90 1469.06 1108.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1469.06" y="991.403" width="117.466" height="117.466" transform="rotate(-90 1469.06 991.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1468.32" y="874.681" width="118.953" height="118.953" transform="rotate(-90 1468.32 874.681)" fill="white" fillOpacity="0.32"></rect>
                                    <rect x="1469.06" y="873.937" width="117.466" height="117.466" transform="rotate(-90 1469.06 873.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1469.06" y="756.471" width="117.466" height="117.466" transform="rotate(-90 1469.06 756.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1468.32" y="639.749" width="118.953" height="118.953" transform="rotate(-90 1468.32 639.749)" fill="white" fillOpacity="0.32"></rect>
                                    <rect x="1469.06" y="639.005" width="117.466" height="117.466" transform="rotate(-90 1469.06 639.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1469.06" y="521.539" width="117.466" height="117.466" transform="rotate(-90 1469.06 521.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1469.06" y="404.073" width="117.466" height="117.466" transform="rotate(-90 1469.06 404.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1586.52" y="1343.8" width="117.466" height="117.466" transform="rotate(-90 1586.52 1343.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1586.52" y="1226.33" width="117.466" height="117.466" transform="rotate(-90 1586.52 1226.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1586.52" y="1108.87" width="117.466" height="117.466" transform="rotate(-90 1586.52 1108.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1586.52" y="991.403" width="117.466" height="117.466" transform="rotate(-90 1586.52 991.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1586.52" y="873.937" width="117.466" height="117.466" transform="rotate(-90 1586.52 873.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1586.52" y="756.471" width="117.466" height="117.466" transform="rotate(-90 1586.52 756.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1586.52" y="639.005" width="117.466" height="117.466" transform="rotate(-90 1586.52 639.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1586.52" y="521.539" width="117.466" height="117.466" transform="rotate(-90 1586.52 521.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1586.52" y="404.073" width="117.466" height="117.466" transform="rotate(-90 1586.52 404.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1703.99" y="1343.8" width="117.466" height="117.466" transform="rotate(-90 1703.99 1343.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1703.99" y="1226.33" width="117.466" height="117.466" transform="rotate(-90 1703.99 1226.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1703.99" y="1108.87" width="117.466" height="117.466" transform="rotate(-90 1703.99 1108.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1703.25" y="992.146" width="118.953" height="118.953" transform="rotate(-90 1703.25 992.146)" fill="white" fillOpacity="0.32"></rect>
                                    <rect x="1703.99" y="991.403" width="117.466" height="117.466" transform="rotate(-90 1703.99 991.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1703.99" y="873.937" width="117.466" height="117.466" transform="rotate(-90 1703.99 873.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1703.99" y="756.471" width="117.466" height="117.466" transform="rotate(-90 1703.99 756.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1703.99" y="639.005" width="117.466" height="117.466" transform="rotate(-90 1703.99 639.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1703.99" y="521.539" width="117.466" height="117.466" transform="rotate(-90 1703.99 521.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1703.99" y="404.073" width="117.466" height="117.466" transform="rotate(-90 1703.99 404.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1821.45" y="1343.8" width="117.466" height="117.466" transform="rotate(-90 1821.45 1343.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1821.45" y="1226.33" width="117.466" height="117.466" transform="rotate(-90 1821.45 1226.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1821.45" y="1108.87" width="117.466" height="117.466" transform="rotate(-90 1821.45 1108.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1821.45" y="991.403" width="117.466" height="117.466" transform="rotate(-90 1821.45 991.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1821.45" y="873.937" width="117.466" height="117.466" transform="rotate(-90 1821.45 873.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1821.45" y="756.471" width="117.466" height="117.466" transform="rotate(-90 1821.45 756.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1821.45" y="639.005" width="117.466" height="117.466" transform="rotate(-90 1821.45 639.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1821.45" y="521.539" width="117.466" height="117.466" transform="rotate(-90 1821.45 521.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1821.45" y="404.073" width="117.466" height="117.466" transform="rotate(-90 1821.45 404.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1938.92" y="1343.8" width="117.466" height="117.466" transform="rotate(-90 1938.92 1343.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1938.92" y="1226.33" width="117.466" height="117.466" transform="rotate(-90 1938.92 1226.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1938.92" y="1108.87" width="117.466" height="117.466" transform="rotate(-90 1938.92 1108.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1938.92" y="991.403" width="117.466" height="117.466" transform="rotate(-90 1938.92 991.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1938.92" y="873.937" width="117.466" height="117.466" transform="rotate(-90 1938.92 873.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1938.92" y="756.471" width="117.466" height="117.466" transform="rotate(-90 1938.92 756.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1938.92" y="639.005" width="117.466" height="117.466" transform="rotate(-90 1938.92 639.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1938.92" y="521.539" width="117.466" height="117.466" transform="rotate(-90 1938.92 521.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="1938.92" y="404.073" width="117.466" height="117.466" transform="rotate(-90 1938.92 404.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                </g>
                                <g filter="url(#svg1776208339_28972_filter1_f_22_4879)">
                                    <ellipse cx="426" cy="714.461" rx="176.199" ry="176.199" transform="rotate(180 426 714.461)" fill="#7173FA" fillOpacity="0.1"></ellipse>
                                </g>
                                <mask id="svg1776208339_28972_mask1_22_4879" maskUnits="userSpaceOnUse" x="0" y="0" width="852" height="1428">
                                    <rect y="1427.43" width="1427.43" height="852" transform="rotate(-90 0 1427.43)" fill="url(#svg1776208339_28972_paint1_radial_22_4879)"></rect>
                                </mask>
                                <g mask="url(#svg1776208339_28972_mask1_22_4879)">
                                    <rect x="14.1263" y="1243.8" width="117.466" height="117.466" transform="rotate(-90 14.1263 1243.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="14.1263" y="1126.33" width="117.466" height="117.466" transform="rotate(-90 14.1263 1126.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="14.1263" y="1008.87" width="117.466" height="117.466" transform="rotate(-90 14.1263 1008.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="14.1263" y="891.403" width="117.466" height="117.466" transform="rotate(-90 14.1263 891.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="14.1263" y="773.937" width="117.466" height="117.466" transform="rotate(-90 14.1263 773.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="14.1263" y="656.471" width="117.466" height="117.466" transform="rotate(-90 14.1263 656.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="13.3828" y="539.749" width="118.953" height="118.953" transform="rotate(-90 13.3828 539.749)" fill="white" fillOpacity="0.32"></rect>
                                    <rect x="14.1263" y="539.005" width="117.466" height="117.466" transform="rotate(-90 14.1263 539.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="14.1263" y="421.539" width="117.466" height="117.466" transform="rotate(-90 14.1263 421.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="14.1263" y="304.073" width="117.466" height="117.466" transform="rotate(-90 14.1263 304.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="131.591" y="1243.8" width="117.466" height="117.466" transform="rotate(-90 131.591 1243.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="131.591" y="1126.33" width="117.466" height="117.466" transform="rotate(-90 131.591 1126.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="131.591" y="1008.87" width="117.466" height="117.466" transform="rotate(-90 131.591 1008.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="131.591" y="891.403" width="117.466" height="117.466" transform="rotate(-90 131.591 891.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="131.591" y="773.937" width="117.466" height="117.466" transform="rotate(-90 131.591 773.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="131.591" y="656.471" width="117.466" height="117.466" transform="rotate(-90 131.591 656.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="131.591" y="539.005" width="117.466" height="117.466" transform="rotate(-90 131.591 539.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="131.591" y="421.539" width="117.466" height="117.466" transform="rotate(-90 131.591 421.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="131.591" y="304.073" width="117.466" height="117.466" transform="rotate(-90 131.591 304.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="249.06" y="1243.8" width="117.466" height="117.466" transform="rotate(-90 249.06 1243.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="249.06" y="1126.33" width="117.466" height="117.466" transform="rotate(-90 249.06 1126.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="249.06" y="1008.87" width="117.466" height="117.466" transform="rotate(-90 249.06 1008.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="249.06" y="891.403" width="117.466" height="117.466" transform="rotate(-90 249.06 891.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="248.316" y="774.681" width="118.953" height="118.953" transform="rotate(-90 248.316 774.681)" fill="white" fillOpacity="0.32"></rect>
                                    <rect x="249.06" y="773.937" width="117.466" height="117.466" transform="rotate(-90 249.06 773.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="249.06" y="656.471" width="117.466" height="117.466" transform="rotate(-90 249.06 656.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="248.316" y="539.749" width="118.953" height="118.953" transform="rotate(-90 248.316 539.749)" fill="white" fillOpacity="0.32"></rect>
                                    <rect x="249.06" y="539.005" width="117.466" height="117.466" transform="rotate(-90 249.06 539.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="249.06" y="421.539" width="117.466" height="117.466" transform="rotate(-90 249.06 421.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="249.06" y="304.073" width="117.466" height="117.466" transform="rotate(-90 249.06 304.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="366.525" y="1243.8" width="117.466" height="117.466" transform="rotate(-90 366.525 1243.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="366.525" y="1126.33" width="117.466" height="117.466" transform="rotate(-90 366.525 1126.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="366.525" y="1008.87" width="117.466" height="117.466" transform="rotate(-90 366.525 1008.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="366.525" y="891.403" width="117.466" height="117.466" transform="rotate(-90 366.525 891.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="366.525" y="773.937" width="117.466" height="117.466" transform="rotate(-90 366.525 773.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="366.525" y="656.471" width="117.466" height="117.466" transform="rotate(-90 366.525 656.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="366.525" y="539.005" width="117.466" height="117.466" transform="rotate(-90 366.525 539.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="366.525" y="421.539" width="117.466" height="117.466" transform="rotate(-90 366.525 421.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="366.525" y="304.073" width="117.466" height="117.466" transform="rotate(-90 366.525 304.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="483.99" y="1243.8" width="117.466" height="117.466" transform="rotate(-90 483.99 1243.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="483.99" y="1126.33" width="117.466" height="117.466" transform="rotate(-90 483.99 1126.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="483.99" y="1008.87" width="117.466" height="117.466" transform="rotate(-90 483.99 1008.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="483.246" y="892.146" width="118.953" height="118.953" transform="rotate(-90 483.246 892.146)" fill="white" fillOpacity="0.32"></rect>
                                    <rect x="483.99" y="891.403" width="117.466" height="117.466" transform="rotate(-90 483.99 891.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="483.99" y="773.937" width="117.466" height="117.466" transform="rotate(-90 483.99 773.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="483.99" y="656.471" width="117.466" height="117.466" transform="rotate(-90 483.99 656.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="483.99" y="539.005" width="117.466" height="117.466" transform="rotate(-90 483.99 539.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="483.99" y="421.539" width="117.466" height="117.466" transform="rotate(-90 483.99 421.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="483.99" y="304.073" width="117.466" height="117.466" transform="rotate(-90 483.99 304.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="601.454" y="1243.8" width="117.466" height="117.466" transform="rotate(-90 601.454 1243.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="601.454" y="1126.33" width="117.466" height="117.466" transform="rotate(-90 601.454 1126.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="601.454" y="1008.87" width="117.466" height="117.466" transform="rotate(-90 601.454 1008.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="601.454" y="891.403" width="117.466" height="117.466" transform="rotate(-90 601.454 891.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="601.454" y="773.937" width="117.466" height="117.466" transform="rotate(-90 601.454 773.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="601.454" y="656.471" width="117.466" height="117.466" transform="rotate(-90 601.454 656.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="601.454" y="539.005" width="117.466" height="117.466" transform="rotate(-90 601.454 539.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="601.454" y="421.539" width="117.466" height="117.466" transform="rotate(-90 601.454 421.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="601.454" y="304.073" width="117.466" height="117.466" transform="rotate(-90 601.454 304.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="718.923" y="1243.8" width="117.466" height="117.466" transform="rotate(-90 718.923 1243.8)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="718.923" y="1126.33" width="117.466" height="117.466" transform="rotate(-90 718.923 1126.33)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="718.923" y="1008.87" width="117.466" height="117.466" transform="rotate(-90 718.923 1008.87)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="718.923" y="891.403" width="117.466" height="117.466" transform="rotate(-90 718.923 891.403)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="718.923" y="773.937" width="117.466" height="117.466" transform="rotate(-90 718.923 773.937)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="718.923" y="656.471" width="117.466" height="117.466" transform="rotate(-90 718.923 656.471)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="718.923" y="539.005" width="117.466" height="117.466" transform="rotate(-90 718.923 539.005)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="718.923" y="421.539" width="117.466" height="117.466" transform="rotate(-90 718.923 421.539)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                    <rect x="718.923" y="304.073" width="117.466" height="117.466" transform="rotate(-90 718.923 304.073)" stroke="white" strokeOpacity="0.4" strokeWidth="1.48691"></rect>
                                </g>
                                <g opacity="0.58">
                                    <g opacity="0.6" filter="url(#svg1776208339_28972_filter2_f_22_4879)">
                                        <path d="M-228.098 378.287L-1050.4 128.333C-1063.9 124.228 -1076.01 116.47 -1085.38 105.916C-1132.22 53.1508 -1088.79 -29.5111 -1018.76 -20.8692L47.1415 110.671C59.3165 112.173 71.3005 114.947 82.8973 118.948L639.85 311.082C674.015 322.868 703.711 344.91 724.885 374.199L938.273 669.371C953.577 690.539 960.667 716.548 958.225 742.555C956.117 833.732 850.695 883.241 779.184 826.638L650.695 724.935C639.09 715.749 626.37 708.068 612.838 702.073L185.524 512.772L-228.098 378.287Z" fill="url(#svg1776208339_28972_paint2_linear_22_4879)" fillOpacity="0.75"></path>
                                        <path d="M-228.098 378.287L-1050.4 128.333C-1063.9 124.228 -1076.01 116.47 -1085.38 105.916C-1132.22 53.1508 -1088.79 -29.5111 -1018.76 -20.8692L47.1415 110.671C59.3165 112.173 71.3005 114.947 82.8973 118.948L639.85 311.082C674.015 322.868 703.711 344.91 724.885 374.199L938.273 669.371C953.577 690.539 960.667 716.548 958.225 742.555C956.117 833.732 850.695 883.241 779.184 826.638L650.695 724.935C639.09 715.749 626.37 708.068 612.838 702.073L185.524 512.772L-228.098 378.287Z" fill="url(#svg1776208339_28972_paint3_linear_22_4879)" fillOpacity="0.6"></path>
                                    </g>
                                    <g filter="url(#svg1776208339_28972_filter3_f_22_4879)">
                                        <path d="M863.231 698.905C853.941 702.535 809.023 655.307 710.256 605.275C582.873 540.748 408.065 448.251 206.558 333.118C-151.186 128.716 -390.261 61.5351 -368.989 53.2246C-347.718 44.914 35.7183 91.8943 393.463 296.296C599.239 440.009 690.244 542.668 775.597 601.107C860.95 659.545 872.821 695.159 863.231 698.905Z" fill="url(#svg1776208339_28972_paint4_linear_22_4879)"></path>
                                    </g>
                                    <g filter="url(#svg1776208339_28972_filter4_f_22_4879)">
                                        <path d="M574.499 627.231C560.078 632.204 404.174 542.535 215.531 432.972C138.803 388.409 39.9681 346.022 -40.8119 295.434C-320.197 120.471 -707.176 164.772 -686.889 157.777C-666.602 150.782 -99.349 131.6 180.036 306.563C459.421 481.526 594.786 620.236 574.499 627.231Z" fill="url(#svg1776208339_28972_paint5_linear_22_4879)"></path>
                                    </g>
                                    <g filter="url(#svg1776208339_28972_filter5_f_22_4879)">
                                        <path d="M77.9678 412.038C52.6271 421.34 -201.069 289.418 -488.677 117.38C-776.285 -54.6566 -258.034 341.233 -232.693 331.931C-207.353 322.629 -684.519 -88.3428 -396.91 83.6943C-109.302 255.731 103.308 402.736 77.9678 412.038Z" fill="url(#svg1776208339_28972_paint6_linear_22_4879)"></path>
                                    </g>
                                </g>
                            </g>
                            <defs>
                                <filter id="svg1776208339_28972_filter0_f_22_4879" x="1172.42" y="340.88" width="947.163" height="947.162" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                                    <feGaussianBlur stdDeviation="148.691" result="effect1_foregroundBlur_22_4879"></feGaussianBlur>
                                </filter>
                                <filter id="svg1776208339_28972_filter1_f_22_4879" x="-47.5815" y="240.88" width="947.163" height="947.162" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                                    <feGaussianBlur stdDeviation="148.691" result="effect1_foregroundBlur_22_4879"></feGaussianBlur>
                                </filter>
                                <filter id="svg1776208339_28972_filter2_f_22_4879" x="-1293.71" y="-210.299" width="2441.21" height="1249.79" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                                    <feGaussianBlur stdDeviation="94.4013" result="effect1_foregroundBlur_22_4879"></feGaussianBlur>
                                </filter>
                                <filter id="svg1776208339_28972_filter3_f_22_4879" x="-436.382" y="-13.7538" width="1368.48" height="778.937" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                                    <feGaussianBlur stdDeviation="33.0405" result="effect1_foregroundBlur_22_4879"></feGaussianBlur>
                                </filter>
                                <filter id="svg1776208339_28972_filter4_f_22_4879" x="-753.737" y="87.3881" width="1396.3" height="606.121" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                                    <feGaussianBlur stdDeviation="33.0405" result="effect1_foregroundBlur_22_4879"></feGaussianBlur>
                                </filter>
                                <filter id="svg1776208339_28972_filter5_f_22_4879" x="-641.421" y="-24.4257" width="787.517" height="503.008" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                                    <feGaussianBlur stdDeviation="33.0405" result="effect1_foregroundBlur_22_4879"></feGaussianBlur>
                                </filter>
                                <radialGradient id="svg1776208339_28972_paint0_radial_22_4879" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1933.72 1953.43) rotate(90) scale(525.162 879.853)">
                                    <stop stopColor="#D9D9D9" stopOpacity="0.2"></stop>
                                    <stop offset="0.802083" stopColor="#D9D9D9" stopOpacity="0"></stop>
                                </radialGradient>
                                <radialGradient id="svg1776208339_28972_paint1_radial_22_4879" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(713.717 1853.43) rotate(90) scale(525.162 879.853)">
                                    <stop stopColor="#D9D9D9" stopOpacity="0.2"></stop>
                                    <stop offset="0.802083" stopColor="#D9D9D9" stopOpacity="0"></stop>
                                </radialGradient>

                                <motion.linearGradient custom={8} initial="hidden" animate="visible" variants={imgAnimation} id="svg1776208339_28972_paint2_linear_22_4879" x1="-562.795" y1="266.799" x2="407.24" y2="1546.29" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#5983FB"></stop>
                                    <stop offset="1" stopColor="#00D1FF"></stop>
                                </motion.linearGradient>
                                <motion.linearGradient custom={8} initial="hidden" animate="visible" variants={imgAnimation} id="svg1776208339_28972_paint3_linear_22_4879" x1="-340.719" y1="362.134" x2="987.211" y2="248.2" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#00D1FF"></stop>
                                    <stop offset="1" stopColor="#846CF9"></stop>
                                </motion.linearGradient>
                                <motion.linearGradient custom={8} initial="hidden" animate="visible" variants={imgAnimation} id="svg1776208339_28972_paint4_linear_22_4879" x1="35.264" y1="229.976" x2="304.866" y2="920.047" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#E5F3FF"></stop>
                                    <stop offset="1" stopColor="#7173FA"></stop>
                                </motion.linearGradient>
                                <motion.linearGradient custom={8} initial="hidden" animate="visible" variants={imgAnimation} id="svg1776208339_28972_paint5_linear_22_4879" x1="-102.599" y1="217.235" x2="90.6208" y2="777.602" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#E5F3FF"></stop>
                                    <stop offset="1" stopColor="#627CFA"></stop>
                                </motion.linearGradient>
                                <motion.linearGradient custom={8} initial="hidden" animate="visible" variants={imgAnimation} id="svg1776208339_28972_paint6_linear_22_4879" x1="70.6049" y1="432.946" x2="-37.9865" y2="57.2986" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#E5F3FF"></stop>
                                    <stop offset="1" stopColor="#5D80FA"></stop>
                                </motion.linearGradient>
                            </defs>
                        </svg>
                    </motion.div>
                </div>
            </div>
            <div className="framer-1wrrtkp" >
                <div className="framer-ofssek" >
                    <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'} ref={ref} whileInView="visible" custom={1} variants={textAnimation} className="framer-e61qcl" >
                        <div className="framer-hgg6ej" data-border="true">
                            <div className="framer-1ps7qll">
                                <div className="svgContainer" >
                                    <div id="container_svg1932578656_547">
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1932578656_547">
                                            <path d="M10.2545 20.7818L6.83666 14.5613L0 11.4515L6.83666 8.34171L10.2545 2.12116L13.6723 8.34171L20.5089 11.4515L13.6723 14.5613L10.2545 20.7818ZM18.6805 20.5557L20.2604 23.4307L21.8402 20.5557L25 19.1182L21.8402 17.6807L20.2604 14.8057L18.6805 17.6807L15.5207 19.1182L18.6805 20.5557ZM20.0845 4.90321L21.3136 7.139L22.5428 4.90321L25 3.78483L22.5428 2.66646L21.3136 0.430664L20.0845 2.66646L17.6272 3.78483L20.0845 4.90321Z" fill="white"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-p5w47l">
                                <div className="framer-wla99d" >
                                    <p className="framer-text">
                                        <span className="framer-text">{context.button}</span></p>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                <div className="framer-wla99d" >
                                    <p className="framer-text">
                                        <span className="framer-text">{context.button}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="framer-1chwo98">
                            <div className="framer-gj8r3p" >
                                <div className="framer-1ca8cvs-container"  >
                                    <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                                        <h1 >{context.title_highlight}</h1>
                                    </div>
                                    <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                        <h1 >{context.title_highlight}</h1>
                                    </div>
                                    <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                                        <h1 >{context.title_highlight}</h1>
                                    </div>
                                </div>
                                <div className="framer-1f73tpq" >
                                    <h1 className="framer-text framer-styles-preset-13kmdo8" data-styles-preset="bRTNNXE_o"><span data-text-fill="true" className="framer-text"> {context.title_top} </span></h1>
                                </div>
                            </div>
                            <div className="framer-1jnfu0l" >
                                <h1 className="framer-text framer-styles-preset-13kmdo8" data-styles-preset="bRTNNXE_o"><span data-text-fill="true" className="framer-text">{context.title_bottom}</span></h1>
                            </div>
                        </div>
                        <div className="ssr-variant hidden-p5w47l">
                            <div className="framer-1gjs8ze"
                                >
                                <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM">{context.text}</p>
                            </div>
                        </div>
                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                            <div className="framer-1gjs8ze"
                                >
                                <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM">{context.text}</p>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'} ref={ref} whileInView="visible" custom={2} variants={textAnimation} className="framer-wpl817"  >
                        <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                            <div className="framer-1yfo5ku-container" >
                                <div className="framer-M5MRE framer-v-86m2yc" tabIndex={0}>

                                </div>
                            </div>
                        </div>
                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                            <div className="framer-1yfo5ku-container" >
                                <div className="framer-M5MRE framer-v-86m2yc" tabIndex={0}>

                                </div>
                            </div>
                        </div>
                        <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                            <div className="framer-1yfo5ku-container" >
                                <div className="framer-M5MRE framer-v-86m2yc" tabIndex={0}>

                                </div>
                            </div>
                        </div>
                        <div className="framer-14k2fe4"  >
                            <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/XDW8A8i1PZ1VsETG6Rlwc6iofVk.svg" alt="" /></div>
                        </div>
                    </motion.div>
                    {/* <div className="framer-1nwyw2w-container" >
                        <section >
                            <ul>
                                <li>
                                    <div className="framer-1b5foaf"  >
                                        <div className="framer-15rf8eu" >
                                            <div data-framer-component-type="SVG" className="framer-h6hqo4">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 221 32"><use href="#svg-1634955118_7115"></use></svg></div>
                                            </div>
                                        </div>
                                        <div className="framer-1yspppi" >
                                            <div data-framer-component-type="SVG" className="framer-x9fjzq">
                                                <div className="svgContainer"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 143 27"><use href="#svg-52545271_2176"></use></svg></div>
                                            </div>
                                        </div>
                                        <div className="framer-euc7iw" >
                                            <div data-framer-component-type="SVG" className="framer-bgc34j">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 156 30"><use href="#svg-1864239402_2515"></use></svg></div>
                                            </div>
                                        </div>
                                        <div className="framer-1wcn9io" >
                                            <div data-framer-component-type="SVG" className="framer-1qxxihe">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 189 35"><use href="#svg1662844923_3680"></use></svg></div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </section>
                    </div> */}
                </div>
            </div>
        </section >
    )
};

export default Header
