import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import "./index.scss"
import PNG from "./solCode.png"
const Clients = () => {
    const [context, setContext] = useState({ title_highlight: "", title_top: "", title: "", note: "", text: "", button: "", paragraph: [""] });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);
    const scrollIntoView = (e: any, elementId: string) => {
        // prevent default behavior
        e.preventDefault();
        const element = document.getElementById(elementId);
        if (element) element.scrollIntoView({ behavior: "smooth" });
    };
    return (
        <section className="framer-1r97wfd"  >
            <div className="framer-12no29k" >
                <div className="framer-1urlfsk"  >
                    <div className="framer-jzz0h2"  >
                        <div className="framer-1tybo1s"  ></div>
                        <div className="framer-12yh7j2"  >
                            <div data-framer-background-image-wrapper="true"><img src={PNG} alt="" loading="lazy" /></div>
                        </div>
                    </div>
                    <div className="framer-dzdjvj"  >
                        <div className="framer-mrehk0"  >
                            <div className="framer-1ho5ydl hidden-18ccrvo hidden-p5w47l"  >
                                <div className="framer-mbx9bf" >
                                    <p className="framer-text"><span
                                        className="framer-text">{context.note}</span></p>
                                </div>
                                <div data-framer-component-type="SVG" className="framer-kqtwxw">
                                    <div className="svgContainer" >    <div id="container_svg1503322473_433">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1503322473_433">
                                            <line y1="1.8291" x2="449" y2="1.8291" stroke="url(#svg1503322473_433_paint0_linear_22_5947)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg1503322473_433_paint0_linear_22_5947" x1="0" y1="2.8291" x2="619" y2="2.8291" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                            </div>
                            <div className="framer-1a1pvl9"  >
                                <div className="framer-10opef8"  >
                                    <div className="framer-1gpy6tb-container" >
                                        <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title_highlight}</span></h2>
                                    </div>
                                    <div className="framer-2taaz7" >
                                        <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title_top}</span></h2>
                                    </div>
                                </div>
                                <div className="framer-1yz1r9y" >
                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}</span></h2>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                                <div className="framer-1vjfh25"
                                    >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                <div className="framer-1vjfh25"
                                    >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                                <div className="framer-1vjfh25"
                                    >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                </div>
                            </div>
                            <div className="framer-1huo939 hidden-72rtr7"  >
                                <div data-framer-component-type="SVG" className="framer-1l6d1of">
                                    <div className="svgContainer" >  <div id="container_svg599100874_424">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg599100874_424">
                                            <line x1="449" y1="1.5" y2="1.5" stroke="url(#svg599100874_424_paint0_linear_22_5286)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg599100874_424_paint0_linear_22_5286" x1="449" y1="0.5" x2="-170" y2="0.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                                <div className="framer-d174w6" >
                                    <p
                                        className="framer-text">{context.note}</p>
                                </div>
                                <div data-framer-component-type="SVG" className="framer-6md674">
                                    <div className="svgContainer" >    <div id="container_svg1312763725_421">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1312763725_421">
                                            <line y1="1.5" x2="449" y2="1.5" stroke="url(#svg1312763725_421_paint0_linear_22_5288)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg1312763725_421_paint0_linear_22_5288" x1="0" y1="2.5" x2="619" y2="2.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                            </div>
                        </div>
                        <div className="framer-1tehynk" >
                            {context.paragraph.map((item) => (
                                <div className="framer-ulnt9v" key={item}>
                                    <div className="framer-1n8goct"  >
                                        <div data-framer-component-type="SVG" className="framer-1uj7sjp">
                                            <div className="svgContainer" >   <div id="container_svg370885021_714">
                                                <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg370885021_714">
                                                    <circle cx="10.5" cy="10.8291" r="10.5" fill="url(#svg370885021_714_paint0_linear_22_5957)" fillOpacity="0.42"></circle>
                                                    <circle cx="10.5" cy="10.8291" r="10" stroke="white" strokeOpacity="0.12"></circle>
                                                    <line x1="6.43514" y1="10.7016" x2="8.89668" y2="13.1631" stroke="white" stopColor="1.23077"></line>
                                                    <line x1="14.4351" y1="8.4952" x2="8.89668" y2="14.0337" stroke="white" stopColor="1.23077"></line>
                                                    <defs>
                                                        <linearGradient id="svg370885021_714_paint0_linear_22_5957" x1="10.5" y1="0.329102" x2="10.5" y2="21.3291" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#D9D9D9"></stop>
                                                            <stop offset="1" stopColor="white" stopOpacity="0.19"></stop>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div></div>
                                        </div>
                                        <div className="ssr-variant hidden-p5w47l">
                                            <div className="framer-1la0vsm" >
                                                <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{item}</p>
                                            </div>
                                        </div>
                                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                            <div className="framer-1la0vsm" >
                                                <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{item}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-framer-component-type="SVG" className="framer-1hincbq hidden-p5w47l">
                                        <div className="svgContainer" >  <div id="container_svg1257096732_433">
                                            <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1257096732_433">
                                                <line y1="1.3291" x2="449" y2="1.3291" stroke="url(#svg1257096732_433_paint0_linear_22_5963)" strokeOpacity="0.25" stopColor="2"></line>
                                                <defs>
                                                    <linearGradient id="svg1257096732_433_paint0_linear_22_5963" x1="0" y1="2.3291" x2="619" y2="2.3291" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="white"></stop>
                                                        <stop offset="1" stopOpacity="0"></stop>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="framer-o9uaah"  >
                            <div className="framer-3bj60h-container">
                                <div className="framer-oyI4k framer-v-693ydj" tabIndex={0}>
                                    <a className="framer-693ydj framer-mesh6j" data-border="true" data-highlight="true"
                                        onClick={(e) => scrollIntoView(e, "contact")}
                                        href="#contact" tabIndex={0}>
                                        <div className="framer-crfnzq"
                                            >
                                            <p
                                                className="framer-text">{context.button}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Clients
