import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { Link } from "react-router-dom"
import "./index.scss"


const Navbar = () => {
    const [open, setOpen] = useState(false)
    const [context, setContext] = useState({
        menu: "", close: "",
        brand: {
            title: "",
            key: ""
        },
        button: {
            title: "",
            key: ""
        },
        paragraph: [{
            title: "",
            key: ""
        }]
    });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);
    return (
        <nav className="navbar" data-border="true"  >
            <div className="framer-3afuy5-container">
                <div className="ssr-variant hidden-1t38e76 hidden-4fkeid">
                    <div className="framer-AxzDx framer-Agnx9 framer-v-lo4vtz" tabIndex={0}>
                        <div className="framer-lo4vtz" >
                            <div className="framer-87ldv4">
                                <div className="framer-28q7tn" >
                                    <Link className="framer-1sxzzqr framer-9wlam3" to={context.brand.key} >
                                        <div className="framer-18eia0k"></div>
                                        <div className="framer-1sj1zk">
                                            <p className="framer-text">{context.brand.title}</p>
                                        </div>
                                    </Link>
                                    <div className="framer-1bpgfuw" >
                                        {context.paragraph.map((item) => (
                                            <div className="framer-byc63r" key={item.key}>
                                                <p className="framer-text"><a className="framer-text framer-styles-preset-1j1173s" data-styles-preset="lc8fCrhtM" href={context.brand.key}>{context.brand.title}</a></p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="framer-ls9ti8" >
                                    <div className="framer-fpsl16-container">
                                        <div className="framer-oyI4k framer-v-693ydj" tabIndex={0}>
                                            <a className="framer-693ydj framer-mesh6j" data-border="true" data-highlight="true"
                                                href="https://fluxtemplates.lemonsqueezy.com/checkout?cart=26785bc9-1384-41b9-941b-335461660489" target="_blank" rel="noopener noreferrer" tabIndex={0}>
                                                <div className="framer-crfnzq" >
                                                    <p className="framer-text">Contact Us</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ssr-variant hidden-4fkeid hidden-1fdb8e9">
                    <div className="framer-AxzDx framer-Agnx9 framer-v-11x0qzs" tabIndex={0}>
                        <div className="framer-lo4vtz" data-framer-name="Tablet">
                            <div className="framer-87ldv4">
                                <div className="framer-28q7tn" data-framer-name="Navbar Wrap">
                                    <Link className="framer-1sxzzqr framer-9wlam3" to={context.brand.key} data-framer-page-link-current="true">
                                        <div data-framer-component-type="SVG" className="framer-18eia0k"></div>
                                        <div className="framer-1sj1zk" >
                                            <p className="framer-text">{context.brand.title}</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="framer-ls9ti8" data-framer-name="CTA Button" onClick={() => { setOpen(!open) }}>
                                    <div className="framer-fpsl16-container">
                                        <div className="framer-oyI4k framer-v-693ydj" tabIndex={0}>
                                            <a className="framer-693ydj framer-mesh6j" data-border="true" data-framer-name="Glass" data-highlight="true"
                                                tabIndex={0}>
                                                <div className="framer-crfnzq"
                                                    >
                                                    <p className="framer-text">{open ? context.close : context.menu}</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {open ?
                                <div className="framer-1xjaemu">
                                    <div className="framer-fbp3j2" data-framer-name="Navlinks" >
                                        {context.paragraph.map((item) => (
                                            <div className="framer-1bqhzgq"  key={item.key}>
                                                <p className="framer-text" >
                                                    <a className="framer-text framer-styles-preset-1j1173s" data-styles-preset="lc8fCrhtM" href="./about">{item.title}</a>
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                </div>
                <div className="ssr-variant hidden-1t38e76 hidden-1fdb8e9">
                    <div className="framer-AxzDx framer-Agnx9 framer-v-11x0qzs" tabIndex={0}>
                        <div className="framer-lo4vtz" data-framer-name="Tablet" >
                            <div className="framer-87ldv4">
                                <div className="framer-28q7tn" data-framer-name="Navbar Wrap">
                                    <Link className="framer-1sxzzqr framer-9wlam3" data-framer-name="Logo" to={context.brand.key} data-framer-page-link-current="true">
                                        <div data-framer-component-type="SVG" data-framer-name="Logo" className="framer-18eia0k"></div>
                                        <div className="framer-1sj1zk" >
                                            <p className="framer-text">{context.brand.title}</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="framer-ls9ti8" data-framer-name="CTA Button" onClick={() => { setOpen(!open) }}>
                                    <div className="framer-fpsl16-container">
                                        <div className="framer-oyI4k framer-v-693ydj" tabIndex={0}>
                                            <a className="framer-693ydj framer-mesh6j" data-border="true" data-framer-name="Glass" data-highlight="true" tabIndex={0}>
                                                <div className="framer-crfnzq"
                                                    >
                                                    <p
                                                        className="framer-text">{open ? context.close : context.menu} </p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {open ? <div className="framer-1xjaemu" >
                                <div className="framer-fbp3j2" data-framer-name="Navlinks" >
                                    {context.paragraph.map((item) => (

                                        <div className="framer-1bqhzgq"  key={item.key} >
                                            <p className="framer-text" >
                                                <a className="framer-text framer-styles-preset-1j1173s" data-styles-preset="lc8fCrhtM" href={item.key}>{item.title}</a>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}




export default Navbar



