import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { setLanguage } from "../../../redux/language";
import "./index.scss"


const Navbar = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [context, setContext] = useState({
        menu: "", close: "",
        brand: {
            title: "",
            key: ""
        },
        button: {
            text: "",
            key: ""
        },
        paragraph: [{
            title: "",
            key: ""
        }],
        languages: [{
            key: "",
            title: ""
        }]
    });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);
    function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
        sessionStorage.setItem("language", event.target.value);
        dispatch(setLanguage(event.target.value))
        sessionStorage.setItem("language", event.target.value)
    }
    const scrollIntoView = (e: any, elementId: string) => {
        // prevent default behavior
        e.preventDefault();
        const element = document.querySelector(elementId);
        console.log(element)
        if (element) element.scrollIntoView({ behavior: "smooth" });
    };



    return (
        <nav className="navbar" data-border="true"  >
            <div className="framer-931it5-container">
                {/* Large Screen ----------------------------------------------------------------*/}
                <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                    <div className="framer-AxzDx framer-Agnx9 framer-v-lo4vtz" tabIndex={0}>
                        <div className="framer-lo4vtz" >
                            <div className="framer-87ldv4">
                                <div className="framer-28q7tn" >
                                    <a className="framer-1sxzzqr framer-9wlam3" href={context.brand.key} >
                                        <div className="framer-18eia0k"></div>
                                        <div className="framer-1sj1zk">
                                            <p className="framer-text">{context.brand.title}</p>
                                        </div>
                                    </a>
                                    <div className="framer-1bpgfuw" >
                                        {context.paragraph.map((item) => (
                                            <div className="framer-byc63r" key={item.key}>
                                                <p className="framer-text">
                                                    <a className="framer-text framer-styles-preset-1j1173s" data-styles-preset="lc8fCrhtM" onClick={(e) => { scrollIntoView(e, item.key)}} href={'#' + item.key}>{item.title}</a></p>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="framer-ls9ti8" >
                                    <div className="framer-fpsl16-container">
                                        <div className="framer-oyI4k framer-v-693ydj hover" tabIndex={0}>
                                            <a className="framer-693ydj framer-mesh6j" data-border="true" data-highlight="true" onClick={(e) => { scrollIntoView(e, "#contact") }}
                                                href={context.button.key} target="_blank" rel="noopener noreferrer" tabIndex={0}>
                                                <div className="framer-crfnzq" >
                                                    <p className="framer-text">{context.button.text}</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-ls9ti8" >
                                    <div className="framer-fpsl16-container">
                                        <div className="framer-oyI4k framer-v-693ydj hover" tabIndex={0}>

                                            <select
                                                onChange={handleChange}
                                                value={language}
                                                className="framer-693ydj framer-mesh6j "
                                                id="language"
                                            >
                                                {context.languages.map((item) => (
                                                    <option value={item.key} key={item.title}>{item.title}</option>
                                                ))}

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Medium Screen ----------------------------------------------------------------*/}
                <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                    <div className="framer-AxzDx framer-Agnx9 framer-v-11x0qzs" tabIndex={0}>
                        <div className="framer-lo4vtz"  >
                            <div className="framer-87ldv4">
                                <div className="framer-28q7tn" >
                                    <a className="framer-1sxzzqr framer-9wlam3" href={context.brand.key} data-framer-page-link-current="true">
                                        <div data-framer-component-type="SVG" className="framer-18eia0k"></div>
                                        <div className="framer-1sj1zk" >
                                            <p className="framer-text">{context.brand.title}</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="framer-ls9ti8" >
                                    <div className="framer-fpsl16-container">
                                        <div className="framer-oyI4k framer-v-693ydj" tabIndex={0}>
                                            <select
                                                onChange={handleChange}
                                                value={language}
                                                className="framer-693ydj framer-mesh6j "
                                                id="language"
                                            >
                                                {context.languages.map((item) => (
                                                    <option value={item.key} key={item.title}>{item.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-ls9ti8" onClick={() => { setOpen(!open) }}>
                                    <div className="framer-fpsl16-container">
                                        <div className="framer-oyI4k framer-v-693ydj" tabIndex={0}>
                                            <a className="framer-693ydj framer-mesh6j" data-border="true" data-highlight="true" tabIndex={0}>
                                                <div className="framer-crfnzq"
                                                    >
                                                    <p
                                                        className="framer-text">{open ? context.close : context.menu} </p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {open ? <div className="framer-1xjaemu" >
                                <div className="framer-fbp3j2"  >
                                    {context.paragraph.map((item) => (

                                        <div className="framer-1bqhzgq"  key={item.key} >
                                            <p className="framer-text" >
                                                <a className="framer-text framer-styles-preset-1j1173s" data-styles-preset="lc8fCrhtM"  onClick={(e) => { scrollIntoView(e, item.key)}} href={'#' + item.key}>{item.title}</a>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                </div>

                {/* Small Screen ---------------------------------------------------------------- */}
                <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                    <div className="framer-AxzDx framer-Agnx9 framer-v-11x0qzs" tabIndex={0}>
                        <div className="framer-lo4vtz" >
                            <div className="framer-87ldv4">
                                <div className="framer-28q7tn" >
                                    <a className="framer-1sxzzqr framer-9wlam3" href={context.brand.key} data-framer-page-link-current="true">
                                        <div data-framer-component-type="SVG" className="framer-18eia0k"></div>
                                        <div className="framer-1sj1zk" >
                                            <p className="framer-text">{context.brand.title}</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="framer-ls9ti8" >
                                    <div className="framer-fpsl16-container">
                                        <div className="framer-oyI4k framer-v-693ydj " tabIndex={0}>
                                            <select
                                                onChange={handleChange}
                                                value={language}
                                                className="framer-693ydj framer-mesh6j "
                                                id="language"
                                            >
                                                {context.languages.map((item) => (
                                                    <option value={item.key} key={item.title}>{item.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-ls9ti8" onClick={() => { setOpen(!open) }}>
                                    <div className="framer-fpsl16-container">
                                        <div className="framer-oyI4k framer-v-693ydj " tabIndex={0}>
                                            <a className="framer-693ydj framer-mesh6j" data-border="true" data-highlight="true"
                                                tabIndex={0}>
                                                <div className="framer-crfnzq"
                                                    >
                                                    <p className="framer-text">{open ? context.close : context.menu}</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {open ?
                                <div className="framer-1xjaemu">
                                    <div className="framer-fbp3j2"  >
                                        {context.paragraph.map((item) => (
                                            <div className="framer-1bqhzgq"  key={item.key}>
                                                <p className="framer-text" >
                                                    <a className="framer-text framer-styles-preset-1j1173s" data-styles-preset="lc8fCrhtM" onClick={(e) => { scrollIntoView(e, item.key)}} href={'#' + item.key}>{item.title}</a>
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}




export default Navbar



