import React, { useEffect, useState } from 'react'
import "./index.scss"
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import Svg from "./svg/cub.svg"
import Advance from "./svg/advance.svg"
import Secure from "./svg/secure.svg"
import Right from "./svg/right-line.svg"
import Friendly from "./svg/friendly.svg"
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
const textAnimation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: (custom: any) => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.2, duration: 1 },
    }),
};
const imgAnimation = {
    hidden: {
        opacity: 0,
    },
    visible: (custom: any) => ({
        opacity: 1,
        transition: { delay: custom * 0.2, duration: 1 },
    }),
};
const developmentPrinciples = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const [context, setContext] = useState({
        title_highlight: "", title: "", note: "", text: "",
        cards: [{ title: "", text: "", image: "" }],
    });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);
    return (
        <section className="framer-1oyfwvc" id="principles" >
            <div className="ssr-variant hidden-p5w47l">
                <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'} ref={ref} whileInView="visible" custom={2} variants={imgAnimation} className="framer-1wu4mmx common"  >
                    <div data-framer-component-type="SVG" className="framer-8pw6ho" >
                        <div className="svgContainer" >
                            <img src={Svg} alt="" />
                        </div>
                    </div>
                    <div className="framer-1iy7ri3"  ></div>
                </motion.div>
            </div>

            <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'} ref={ref} whileInView="visible" custom={2} variants={imgAnimation} className="framer-1wu4mmx"  >
                    <div data-framer-component-type="SVG" className="framer-8pw6ho">
                        <div className="svgContainer" >
                            <img src={Svg} alt="" />
                        </div>
                    </div>
                    <div className="framer-1iy7ri3" ></div>
                </motion.div>
            </div>
            <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'}
                ref={ref} whileInView="visible" custom={2} variants={textAnimation} className="framer-cylkc8" >
                <div className="framer-hqsyw0"  >
                    <div className="framer-g9rlpx"  >
                        <div className="framer-w1kx9j">
                            <div data-framer-component-type="SVG" className="framer-1sgd7z5">
                                <div className="svgContainer" >
                                    {/* <img src={Left} alt="" /> */}
                                    <img src={Right} alt="" className='left' />
                                </div>
                            </div>
                            <div className="framer-18z5ztj" >
                                <p className="framer-text"><span
                                    className="framer-text">{context.note}</span></p>
                            </div>
                            <div data-framer-component-type="SVG" className="framer-ebbv65">
                                <div className="svgContainer" >
                                    <img src={Right} alt="" className='right' />
                                </div>
                            </div>
                        </div>
                        <div className="framer-1l5i75g" style={{width:'100%'}}>
                            <div className="framer-l6sovz-container"  >
                                <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                                    <h1 >{context.title_highlight}</h1>
                                </div>
                                <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                    <h1 >{context.title_highlight}</h1>
                                </div>
                                <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                                    <h1  >{context.title_highlight}</h1>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-18ccrvo">
                                <div className="framer-bte1da" >
                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}</span></h2>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                                <div className="framer-bte1da" >
                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="framer-y562of"
                            >
                            <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM"> {context.text}</p>
                        </div>
                    </div>
                </div>
            </motion.div>
            <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'}
                ref={ref} whileInView="visible" custom={2} variants={textAnimation} className="framer-yvjwy2"  >
                {context.cards.map((item) => (
                    <div className="framer-1sfg8nx" key={item.title} >
                        <div data-framer-component-type="SVG" className="framer-zt7lmv">
                            <div className="svgContainer" >     <div id="container_svg82978118_6280">
                                <img src={item.image === "Secure" ? Secure : item.image === "Advance" ? Advance : Friendly} alt='' />
                            </div></div>
                        </div>
                        <div className="framer-ddvqi8"  >
                            <div className="framer-14vj6ad" >
                                <h3 className="framer-text framer-styles-preset-12s9psp" data-styles-preset="kCl4wtJM7"><span data-text-fill="true" className="framer-text">{item.title}</span></h3>
                            </div>
                            <div className="framer-12jripk"
                                >
                                <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM">{item.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="framer-xwt5a8"  ></div>
            </motion.div>
        </section>
    )
};

export default developmentPrinciples
