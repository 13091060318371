import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { setLanguage } from '../../../redux/language';
import "./index.scss"
const Footer = () => {
    const [context, setContext] = useState({
        title: "", text: "", languages: [{
            key: "",
            title: ""
        }]
    });
    const dispatch = useDispatch()
    const { language } = useSelector((state: RootState) => state.language);

    useEffect(() => {
        const fetchData = async () => {
            let footer = await import(`./languages/${language}`);
            setContext(footer);
        };
        fetchData();
    }, [language]);
    useEffect(() => {
        const oldLanguage = sessionStorage.getItem("language")
        if (oldLanguage) {
            dispatch(setLanguage(oldLanguage))
        }
    }, [])
    function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
        sessionStorage.setItem("language", event.target.value);
        dispatch(setLanguage(event.target.value))
        sessionStorage.setItem("language", event.target.value)
    }

    return (
        <div className="framer-1eu4ola-container">
            <div className="ssr-variant  hidden-1t38e76 hidden-4fkeid">
                <div className="framer-QisTT framer-EjWs1 framer-Adeeo framer-Agnx9 framer-yRxrJ framer-v-18ei3y1" tabIndex={0}>
                    <footer className="framer-18ei3y1" data-framer-name="Desktop" >
                        <div className="framer-99vg7j" data-framer-name="Container">
                            <div className="framer-1dc1oib" data-framer-name="Footer Body">
                                <div className="framer-gj4g34" data-framer-name="Footer Top Wrap">
                                    <div data-framer-component-type="SVG" data-framer-name="Line 61" className="framer-17sxb2k">
                                        <div className="svgContainer" ><div id="container_svg262990750_166">
                                            <svg width="1304" height="4" viewBox="-1 -1 1304 4" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg262990750_166">
                                                <line y1="0.72168" x2="1302" y2="0.72168" stroke="white"></line>
                                            </svg>
                                        </div></div>
                                    </div>
                                </div>
                                <div className="framer-ys4oie" data-framer-name="Footer Main Wrap">
                                    <div className="framer-1l3t5an" data-framer-name="Footer Main">
                                        <a className="framer-17uvgnt framer-q9lbgg" data-framer-name="Logo Wrap" href="./" data-framer-page-link-current="true">
                                            <div data-framer-component-type="SVG" data-framer-name="Logo"
                                                className="framer-11ew9a1"></div>
                                            <div className="framer-w5bnc6"
                                                >
                                                <p className="framer-text">{context.title}</p>
                                            </div>
                                        </a>
                                        <div className="framer-icvppe" >
                                            <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM" >{context.text} </p>
                                        </div>

                                    </div>
                                    <div className="framer-14vrh6b" data-framer-name="Footer Links Wrap">
                                        <div className="framer-je2t0f">
                                            <select
                                                className='framer-text select'
                                                value={language}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e)}
                                            >
                                                {context.languages.map((item, index) => (
                                                    <option value={item.key} key={index}>
                                                        {item.title}
                                                    </option>
                                                ))}
                                            </select>
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001216" href="https://twitter.com" target="_blank" rel="noopener" className="framer-6lu5oz framer-q9lbgg">
                                                <div className="svgContainer"><div id="container_svg896376_567">
                                                    <svg width="40" height="37" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg896376_567"><path d="M27.953 14.525c.011.176.011.352.011.53 0 5.422-4.128 11.675-11.675 11.675v-.004A11.614 11.614 0 0 1 10 24.887a8.234 8.234 0 0 0 6.073-1.7 4.108 4.108 0 0 1-3.834-2.85 4.104 4.104 0 0 0 1.853-.07 4.103 4.103 0 0 1-3.292-4.023v-.052a4.07 4.07 0 0 0 1.862.514 4.106 4.106 0 0 1-1.27-5.478 11.651 11.651 0 0 0 8.456 4.288 4.107 4.107 0 0 1 6.993-3.742c.919-.182 1.8-.52 2.606-.997a4.123 4.123 0 0 1-1.805 2.27A8.241 8.241 0 0 0 30 12.401a8.34 8.34 0 0 1-2.047 2.124Z" fill="#fff"></path></svg>
                                                </div></div>
                                            </a>
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001215" href="https://facebook.com" target="_blank" rel="noopener noreferrer"
                                                className="framer-2ub89w framer-q9lbgg">
                                                <div className="svgContainer" > <div id="container_svg195004633_380">
                                                    <svg width="40" height="41" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg195004633_380"><path d="M30 20.665c0 4.99-3.662 9.128-8.442 9.88v-6.97h2.325l.442-2.883h-2.767V18.82c0-.79.387-1.558 1.625-1.558h1.258v-2.455s-1.142-.195-2.233-.195c-2.278 0-3.766 1.381-3.766 3.88v2.198h-2.533v2.883h2.533v6.97C13.662 29.791 10 25.654 10 20.664c0-5.522 4.477-10 10-10s10 4.477 10 10Z" fill="#fff"></path></svg>
                                                </div></div>
                                            </a>
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001217" href="https://pinterest.com" target="_blank" rel="noopener noreferrer"
                                                className="framer-1q9apjh framer-q9lbgg">
                                                <div className="svgContainer">   <div id="container_svg-1124212260_866">
                                                    <svg width="40" height="41" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg-1124212260_866"><path d="M20.008 10.588C14.481 10.588 10 15.068 10 20.596c0 4.242 2.636 7.867 6.359 9.325-.091-.79-.165-2.01.032-2.875.182-.783 1.17-4.976 1.17-4.976s-.296-.6-.296-1.482c0-1.392.807-2.43 1.812-2.43.856 0 1.269.643 1.269 1.409 0 .856-.544 2.142-.833 3.336-.238.997.503 1.813 1.483 1.813 1.78 0 3.147-1.879 3.147-4.58 0-2.398-1.721-4.07-4.185-4.07-2.85 0-4.522 2.134-4.522 4.342 0 .856.33 1.78.74 2.282a.292.292 0 0 1 .068.287c-.075.313-.247.997-.28 1.137-.042.18-.15.222-.339.132-1.252-.585-2.035-2.406-2.035-3.88 0-3.155 2.29-6.054 6.614-6.054 3.469 0 6.17 2.471 6.17 5.783 0 3.451-2.174 6.227-5.19 6.227-1.013 0-1.969-.527-2.29-1.153l-.625 2.38c-.222.874-.832 1.961-1.244 2.628.94.287 1.93.444 2.967.444 5.527 0 10.008-4.48 10.008-10.008a9.987 9.987 0 0 0-9.992-10.025Z" fill="#fff"></path></svg>
                                                </div></div>
                                            </a>
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001218" href="https://discord.com" target="_blank" rel="noopener noreferrer"
                                                className="framer-qmmn06 framer-q9lbgg">
                                                <div className="svgContainer" > <div id="container_svg-1724145863_1186">
                                                    <svg width="40" height="37" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg-1724145863_1186"><path d="M26.932 12.246a16.49 16.49 0 0 0-4.071-1.263.063.063 0 0 0-.066.031c-.176.313-.37.721-.507 1.041a15.232 15.232 0 0 0-4.572 0 10.557 10.557 0 0 0-.515-1.04.065.065 0 0 0-.065-.032 16.438 16.438 0 0 0-4.071 1.263.054.054 0 0 0-.027.023c-2.593 3.873-3.304 7.652-2.955 11.384a.067.067 0 0 0 .025.046 16.564 16.564 0 0 0 4.995 2.525.064.064 0 0 0 .07-.024 11.81 11.81 0 0 0 1.022-1.661.063.063 0 0 0-.035-.089 11.026 11.026 0 0 1-1.56-.743.065.065 0 0 1-.007-.107c.105-.078.21-.16.31-.242a.062.062 0 0 1 .065-.009c3.273 1.495 6.817 1.495 10.052 0a.061.061 0 0 1 .065.009c.1.082.205.165.311.243a.064.064 0 0 1-.006.107 10.22 10.22 0 0 1-1.56.742.064.064 0 0 0-.035.09c.3.581.644 1.135 1.021 1.66a.064.064 0 0 0 .07.025 16.537 16.537 0 0 0 5.002-2.525.063.063 0 0 0 .026-.046c.417-4.314-.698-8.062-2.957-11.384a.042.042 0 0 0-.025-.024Zm-10.248 9.135c-.986 0-1.798-.905-1.798-2.016s.796-2.016 1.798-2.016c1.01 0 1.813.913 1.797 2.016 0 1.112-.796 2.016-1.797 2.016Zm6.646 0c-.985 0-1.797-.905-1.797-2.016s.795-2.016 1.797-2.016c1.01 0 1.814.913 1.798 2.016 0 1.112-.789 2.016-1.798 2.016Z" fill="#fff"></path></svg>
                                                </div></div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-yd4uzl" data-framer-name="Footer Lower Wrap">
                                    <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-1q5qog7">
                                        <div className="svgContainer"><div id="container_svg262990750_166">
                                            <svg width="1304" height="4" viewBox="-1 -1 1304 4" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg262990750_166">
                                                <line y1="0.72168" x2="1302" y2="0.72168" stroke="white"></line>
                                            </svg>
                                        </div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
            <div className="ssr-variant hidden-4fkeid hidden-1fdb8e9">
                <div className="framer-QisTT framer-EjWs1 framer-Adeeo framer-Agnx9 framer-yRxrJ framer-v-15ysd0k" tabIndex={0}>
                    <footer className="framer-18ei3y1" data-framer-name="Phone" >
                        <div className="framer-99vg7j" data-framer-name="Container">
                            <div className="framer-1dc1oib" data-framer-name="Footer Body">
                                <div className="framer-gj4g34" data-framer-name="Footer Top Wrap">

                                    <div data-framer-component-type="SVG" data-framer-name="Line 61" className="framer-17sxb2k">
                                        <div className="svgContainer"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1304 4"><use href="#svg262990750_166"></use></svg></div>
                                    </div>
                                </div>
                                <div className="framer-ys4oie" data-framer-name="Footer Main Wrap">
                                    <div className="framer-1l3t5an" data-framer-name="Footer Main">
                                        <a className="framer-17uvgnt framer-q9lbgg" data-framer-name="Logo Wrap" href="./" data-framer-page-link-current="true">
                                            <div data-framer-component-type="SVG" data-framer-name="Logo"
                                                className="framer-11ew9a1"></div>
                                            <div className="framer-w5bnc6"
                                                >
                                                <p
                                                    className="framer-text">{context.title}</p>
                                            </div>
                                        </a>
                                        <div className="framer-icvppe" >
                                            <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM">{context.text}</p>
                                        </div>
                                        <select
                                            className='framer-text select'
                                            value={language}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e)}
                                        >
                                            {context.languages.map((item, index) => (
                                                <option value={item.key} key={index}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="framer-je2t0f">
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001216" href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="framer-6lu5oz framer-q9lbgg">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 37"><use href="#svg896376_567"></use></svg></div>
                                            </a>
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001215" href="https://facebook.com" target="_blank" rel="noopener noreferrer"
                                                className="framer-2ub89w framer-q9lbgg">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 41"><use href="#svg195004633_380"></use></svg></div>
                                            </a>
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001217" href="https://pinterest.com" target="_blank" rel="noopener noreferrer"
                                                className="framer-1q9apjh framer-q9lbgg">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 41"><use href="#svg-1124212260_866"></use></svg></div>
                                            </a>
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001218" href="https://discord.com" target="_blank" rel="noopener noreferrer"
                                                className="framer-qmmn06 framer-q9lbgg">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 37"><use href="#svg-1724145863_1186"></use></svg></div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-yd4uzl" data-framer-name="Footer Lower Wrap">
                                    <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-1q5qog7">
                                        <div className="svgContainer"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1304 4"><use href="#svg262990750_166"></use></svg></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
            <div className="ssr-variant  hidden-1t38e76 hidden-1fdb8e9">
                <div className="framer-QisTT framer-EjWs1 framer-Adeeo framer-Agnx9 framer-yRxrJ framer-v-100waq7" tabIndex={0}>
                    <footer className="framer-18ei3y1" data-framer-name="Tablet" >
                        <div className="framer-99vg7j" data-framer-name="Container">
                            <div className="framer-1dc1oib" data-framer-name="Footer Body">
                                <div className="framer-gj4g34" data-framer-name="Footer Top Wrap">
                                    <div data-framer-component-type="SVG" data-framer-name="Line 61" className="framer-17sxb2k">
                                        <div className="svgContainer"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1304 4"><use href="#svg262990750_166"></use></svg></div>
                                    </div>
                                </div>
                                <div className="framer-ys4oie" data-framer-name="Footer Main Wrap">
                                    <div className="framer-1l3t5an" data-framer-name="Footer Main">
                                        <a className="framer-17uvgnt framer-q9lbgg" data-framer-name="Logo Wrap" href="./" data-framer-page-link-current="true">
                                            <div data-framer-component-type="SVG" data-framer-name="Logo"
                                                className="framer-11ew9a1"></div>
                                            <div className="framer-w5bnc6"
                                                >
                                                <p
                                                    className="framer-text">{context.title}</p>
                                            </div>
                                        </a>
                                        <div className="framer-icvppe" >
                                            <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM" >{context.text} </p>
                                        </div>
                                        <select
                                            className='framer-text select'
                                            value={language}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e)}
                                        >
                                            {context.languages.map((item, index) => (
                                                <option value={item.key} key={index}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="framer-je2t0f">
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001216" href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="framer-6lu5oz framer-q9lbgg">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 37"><use href="#svg896376_567"></use></svg></div>
                                            </a>
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001215" href="https://facebook.com" target="_blank" rel="noopener noreferrer"
                                                className="framer-2ub89w framer-q9lbgg">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 41"><use href="#svg195004633_380"></use></svg></div>
                                            </a>
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001217" href="https://pinterest.com" target="_blank" rel="noopener noreferrer"
                                                className="framer-1q9apjh framer-q9lbgg">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 41"><use href="#svg-1124212260_866"></use></svg></div>
                                            </a>
                                            <a data-framer-component-type="SVG" data-framer-name="Frame_1000001218" href="https://discord.com" target="_blank" rel="noopener noreferrer"
                                                className="framer-qmmn06 framer-q9lbgg">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 37"><use href="#svg-1724145863_1186"></use></svg></div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-yd4uzl" data-framer-name="Footer Lower Wrap">
                                    <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-1q5qog7">
                                        <div className="svgContainer"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1304 4"><use href="#svg262990750_166"></use></svg></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
};

export default Footer
