import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import "./index.scss"


const FAQ = () => {
    const [context, setContext] = useState({
        title_highlight: "", title_top: "", title: "", note: "", text: "",
        faq: [{
            title: "", questions: [{
                quiz: "",
                answer: "",
                id: 0
            }]
        }]

    });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let translate = await import(`./languages/${language}`);
            setContext(translate);
        };
        fetchData();
    }, [language]);
    const [active, setActive] = useState(0)
    const [activeQuiz, setActiveQuiz] = useState({
        state: false,
        id: 0
    })
    const handleOpenQuestion = (id: number) => {
        if (id !== activeQuiz.id) {
            setActiveQuiz({
                id,
                state: true
            });
        } else {
            setActiveQuiz({
                id,
                state: !activeQuiz.state
            });
        }
    };
    return (
        <div className='faq'>
        <div className="framer-nprimq-container">
            {/* Large Screen */}
            <div className=" hidden-p5w47l hidden-18ccrvo">
                <div className="framer-LJUxW framer-jUJDS framer-EjWs1 framer-v-1aayn3s" tabIndex={0}>
                    <section className="framer-1aayn3s" >
                        <div className="framer-2am1f1">
                            <div className="framer-fbtuha" >
                                <div className="framer-sbtnlc" >
                                    <div className="framer-izixkc" >
                                        <div className="framer-19waysq" >
                                            <div className="framer-u5fogz"
                                                >
                                                <p className="framer-text">{context.note}</p>
                                            </div>
                                            <div data-framer-component-type="SVG" className="framer-1db3of8">
                                                <div className="svgContainer" >
                                                    <div id="container_svg1418925417_437">
                                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1418925417_437">
                                                            <line y1="1.88281" x2="449" y2="1.88281" stroke="url(#svg1418925417_437_paint0_linear_22_6302)" strokeOpacity="0.35" stopColor="2"></line>
                                                            <defs>
                                                                <linearGradient id="svg1418925417_437_paint0_linear_22_6302" x1="0" y1="2.88281" x2="619" y2="2.88281" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="white"></stop>
                                                                    <stop offset="1" stopOpacity="0"></stop>
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="framer-1rkmp1x" >
                                            <div className="framer-2jsrsv" >
                                                <div className="framer-axfwds" >
                                                    <p className="framer-text">
                                                        <span data-text-fill="true" className="framer-text">
                                                            <span className="framer-text">{context.title_highlight}</span>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="framer-rni9ns" >
                                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW">
                                                        <span data-text-fill="true" className="framer-text">{context.title_top}</span>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="framer-1m021lb" >
                                                <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW">
                                                    <span data-text-fill="true" className="framer-text">{context.title}</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="framer-1umr50u" >
                                        <div className="framer-1wsiscg" >
                                            <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                        </div>
                                        <div className="framer-8yx99d-container">
                                            <div className="framer-oyI4k framer-v-693ydj" tabIndex={0}>
                                                {context.faq.map((item, index) => (
                                                    <div className={`framer-693ydj framer-mesh6j ${index === active ? "active" : ""} `} data-border="true" data-highlight="true"
                                                        onClick={() => {
                                                            setActive(index)
                                                            setActiveQuiz({
                                                                state: false,
                                                                id: 0
                                                            })
                                                        }}
                                                        tabIndex={0} key={item.title}

                                                    >
                                                        <div className="framer-crfnzq"
                                                            >
                                                            <p
                                                                className="framer-text">{item.title}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-9nz7c6" >
                                    {context.faq.map((elem, index) => (
                                        active === index ?

                                            (elem.questions.map((item, index) => (
                                                <React.Fragment key={item.id}>
                                                    <div className="framer-1tve9vk-container"  >
                                                        <div className="framer-KrDQC framer-v-43wrbu" tabIndex={0}>
                                                            <div className="framer-1vbgyyi" data-highlight="true" tabIndex={0} >
                                                                <div className="framer-1t196q0"
                                                                    onClick={() => handleOpenQuestion(item.id)} >
                                                                    <div className={`framer-97smsc ${activeQuiz.id === item.id && activeQuiz.state ? "open" : ""}`} data-border="true"  >
                                                                        <div data-framer-component-type="SVG" className="framer-o5tq9a">
                                                                            <div className="svgContainer" >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 42 42" preserveAspectRatio="none" width="100%" height="100%">
                                                                                    <use href="#svg434534884_140">
                                                                                    </use>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="framer-1it09ja" >
                                                                        <div className="framer-f2wsgf" >
                                                                            <p className="framer-text">{item.quiz}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`framer-18qc69a ${activeQuiz.id === item.id && activeQuiz.state ? "close" : ""} `}  >
                                                                    <div className="framer-slca9b" >
                                                                        <div className="framer-18f3nw9"  >
                                                                            <p className="framer-text" > {item.answer}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {elem.questions.length - 1 !== index ? <div className="framer-bu5nxc"  ></div> : ""}
                                                </React.Fragment>
                                            ))) : ""
                                    ))}

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            
            {/* Medium Screen */}
            <div className=" hidden-p5w47l hidden-72rtr7">
                <div className="framer-LJUxW framer-jUJDS framer-EjWs1 framer-v-gww2m3" tabIndex={0}>
                    <section className="framer-1aayn3s" >
                        <div className="framer-2am1f1">
                            <div className="framer-fbtuha" >
                                <div className="framer-sbtnlc" >
                                    <div className="framer-izixkc" >
                                        <div className="framer-r3i0ys" >
                                            <div data-framer-component-type="SVG" className="framer-p0n910">
                                                <div className="svgContainer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 451 5">
                                                        <use href="#svg562808578_442">
                                                        </use>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="framer-11faj47"
                                                >
                                                <p
                                                    className="framer-text">{context.note}</p>
                                            </div>
                                            <div data-framer-component-type="SVG" className="framer-171v13l">
                                                <div className="svgContainer" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 451 5">
                                                        <use href="#svg1921603383_437">
                                                        </use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="framer-1rkmp1x" >
                                            <div className="framer-2jsrsv" >
                                                <div className="framer-axfwds" >
                                                    <p className="framer-text">
                                                        <span data-text-fill="true" className="framer-text">
                                                            <span className="framer-text">{context.title_highlight}</span>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="framer-rni9ns" >
                                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW">
                                                        <span data-text-fill="true" className="framer-text">{context.title_top}</span>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="framer-1m021lb" >
                                                <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW">
                                                    <span data-text-fill="true" className="framer-text">{context.title}</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="framer-1umr50u" >
                                        <div className="framer-1wsiscg" >
                                            <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                        </div>
                                        <div className="framer-8yx99d-container">
                                            <div className="framer-oyI4k framer-v-693ydj" tabIndex={0}>

                                                {context.faq.map((item, index) => (
                                                    <div className={`framer-693ydj framer-mesh6j ${index === active ? " active" : ""}`} key={`${item.title + index}`} data-border="true" data-highlight="true" onClick={() => {
                                                        setActive(index)
                                                        setActiveQuiz({
                                                            state: false,
                                                            id: 0
                                                        })
                                                    }}
                                                        tabIndex={0}>
                                                        <div className="framer-crfnzq"
                                                            >
                                                            <p
                                                                className="framer-text">{item.title}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-9nz7c6" >
                                    {context.faq.map((elem, index) => (
                                        active === index ?
                                            elem.questions.map((item, index) => (
                                                <React.Fragment key={item.id + index}>
                                                    <div className="framer-1tve9vk-container" >
                                                        <div className="framer-KrDQC framer-v-43wrbu" tabIndex={0}>
                                                            <div className="framer-1vbgyyi" data-highlight="true" tabIndex={0} >
                                                                <div className="framer-1t196q0" onClick={() => handleOpenQuestion(item.id)}>
                                                                    <div className={`framer-97smsc ${activeQuiz.id === item.id && activeQuiz.state ? "open" : ""}`} data-border="true"  >
                                                                        <div data-framer-component-type="SVG" className="framer-o5tq9a">
                                                                            <div className="svgContainer" >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 42 42" preserveAspectRatio="none" width="100%" height="100%">
                                                                                    <use href="#svg434534884_140">
                                                                                    </use>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="framer-1it09ja" >
                                                                        <div className="framer-f2wsgf" >
                                                                            <p className="framer-text">{item.quiz}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`framer-18qc69a ${activeQuiz.id === item.id && activeQuiz.state ? "close" : ""} `}  >
                                                                    <div className="framer-slca9b">
                                                                        <div className="framer-18f3nw9"  >
                                                                            <p className="framer-text" > {item.answer}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {elem.questions.length - 1 !== index ?
                                                        <div className="framer-bu5nxc"  >
                                                        </div> : ""
                                                    }
                                                </React.Fragment>
                                            ))
                                            : ""
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

             {/* Small Screen */}
             <div className=" hidden-18ccrvo hidden-72rtr7">
                <div className="framer-LJUxW framer-jUJDS framer-EjWs1 framer-v-1swwsya" tabIndex={0}>
                    <section className="framer-1aayn3s" >
                        <div className="framer-2am1f1" >
                            <div className="framer-fbtuha" >
                                <div className="framer-sbtnlc" >
                                    <div className="framer-izixkc" >
                                        <div className="framer-r3i0ys" >
                                            <div data-framer-component-type="SVG" className="framer-p0n910">
                                                <div className="svgContainer" >
                                                    <div id="container_svg562808578_442">
                                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg562808578_442">
                                                            <line x1="449" y1="1.16016" y2="1.16016" stroke="url(#svg562808578_442_paint0_linear_34_7299)" strokeOpacity="0.35" strokeWidth="2"></line>
                                                            <defs>
                                                                <linearGradient id="svg562808578_442_paint0_linear_34_7299" x1="449" y1="0.160156" x2="-170" y2="0.160156" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="white"></stop>
                                                                    <stop offset="1" stopOpacity="0"></stop>
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="framer-11faj47"
                                                >
                                                <p
                                                    className="framer-text">{context.note}</p>
                                            </div>
                                            <div data-framer-component-type="SVG" className="framer-171v13l">
                                                <div className="svgContainer" >
                                                    <div id="container_svg1921603383_437">
                                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1921603383_437">
                                                            <line y1="1.16016" x2="449" y2="1.16016" stroke="url(#svg1921603383_437_paint0_linear_34_7301)" strokeOpacity="0.35" strokeWidth="2"></line>
                                                            <defs>
                                                                <linearGradient id="svg1921603383_437_paint0_linear_34_7301" x1="0" y1="2.16016" x2="619" y2="2.16016" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="white"></stop>
                                                                    <stop offset="1" stopOpacity="0"></stop>
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="framer-1rkmp1x" >
                                            <div className="framer-2jsrsv" >
                                                <div className="framer-axfwds" >
                                                    <p
                                                        className="framer-text">
                                                        <span data-text-fill="true" className="framer-text">{context.title_highlight}</span>
                                                    </p>
                                                </div>
                                                <div className="framer-rni9ns" >
                                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW">
                                                        <span data-text-fill="true" className="framer-text">{context.title_top}</span>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="framer-1m021lb" >
                                                <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW">
                                                    <span data-text-fill="true" className="framer-text">{context.title}   <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg434534884_140"><path stroke="#fff" strokeWidth="6" d="M21 0v42M0 20h42"></path></svg></span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="framer-1umr50u" >
                                        <div className="framer-1wsiscg" >
                                            <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                        </div>
                                        <div className="framer-8yx99d-container">
                                            <div className="framer-oyI4k framer-v-693ydj" tabIndex={0} >
                                                {context.faq.map((item, index) => (
                                                    <div className={`framer-693ydj framer-mesh6j ${index === active ? " active" : ""}`} key={`${item.title + index}`} data-border="true" data-highlight="true" onClick={() => {
                                                        setActive(index)
                                                        setActiveQuiz({
                                                            state: false,
                                                            id: 0
                                                        })
                                                    }}
                                                        tabIndex={0}>
                                                        <div className="framer-crfnzq"
                                                            >
                                                            <p
                                                                className="framer-text">{item.title}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-9nz7c6" >
                                    {context.faq.map((elem, index) => (
                                        active === index ?
                                            elem.questions.map((item, index) => (
                                                <React.Fragment key={item.id + index}>
                                                    <div className="framer-1tve9vk-container" >
                                                        <div className="framer-KrDQC framer-v-43wrbu" tabIndex={0}>
                                                            <div className="framer-1vbgyyi" data-highlight="true" tabIndex={0} >
                                                                <div className="framer-1t196q0" onClick={() => handleOpenQuestion(item.id)}>
                                                                    <div className={`framer-97smsc ${activeQuiz.id === item.id && activeQuiz.state ? "open" : ""}`} data-border="true"  >
                                                                        <div data-framer-component-type="SVG" className="framer-o5tq9a">
                                                                            <div className="svgContainer" >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 42 42" preserveAspectRatio="none" width="100%" height="100%">
                                                                                    <use href="#svg434534884_140">
                                                                                    </use>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="framer-1it09ja" >
                                                                        <div className="framer-f2wsgf" >
                                                                            <p className="framer-text">{item.quiz}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`framer-18qc69a ${activeQuiz.id === item.id && activeQuiz.state ? "close" : ""} `}  >
                                                                    <div className="framer-slca9b">
                                                                        <div className="framer-18f3nw9"  >
                                                                            <p className="framer-text" > {item.answer}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {elem.questions.length - 1 !== index ?
                                                        <div className="framer-bu5nxc"  >
                                                        </div> : ""
                                                    }
                                                </React.Fragment>
                                            ))
                                            : ""
                                    ))}

                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </div>
        </div>
    )
};

export default FAQ
