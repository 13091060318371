import React, { useEffect, useState } from 'react'
import "./index.scss"
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';

const imgAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: (custom: any) => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.5, duration: 2 },
    }),
};
const textAnimation = {
    hidden: {
        opacity: 0,
    },
    visible: (custom: any) => ({
        opacity: 1,
        transition: { delay: custom * 0.5, duration: 2 },
    }),
};
const Members = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const [context, setContext] = useState({
        collaborative: "", title: "", note: "", text: "",
        cards: [{ title: "", adress: "", phone: "", img: "" }],

    });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);
    return (
        <section className="framer-1r8s7jc" data-framer-name="Section" >
            <div className="framer-1und9d4" data-framer-name="Container">
                <div className="framer-dasrki" data-framer-name="Body" >
                    <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'} ref={ref} whileInView="visible" custom={1} variants={textAnimation} className="framer-1d7q4in" data-framer-name="Features Headings Wrap" >
                        <div className="framer-10ecp3a" data-framer-name="Tagline Wrap">
                            <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-gzvsc5">
                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 451 5"><use href="#svg1142048594_424"></use></svg></div>
                            </div>
                            <div className="framer-161qdwk" data-framer-name="How we do" >
                                <p
                                    className="framer-text">How we do</p>
                            </div>
                            <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-sg55ij">
                                <div className="svgContainer"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 451 5"><use href="#svg2056266437_421"></use></svg></div>
                            </div>
                        </div>
                        <div className="framer-w6947w" data-framer-name="Features Headings Wrap">
                            <div className="framer-9e8a7h-container" data-framer-name="Heading">
                                <div className="ssr-variant hidden-1t38e76 hidden-4fkeid">
                                    <h1 >{context.collaborative}</h1>
                                </div>
                                <div className="ssr-variant hidden-4fkeid hidden-1fdb8e9">
                                    <h1 >{context.collaborative}</h1>
                                </div>
                                <div className="ssr-variant hidden-1t38e76 hidden-1fdb8e9">
                                    <h1 >{context.collaborative}</h1>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-4fkeid">
                                <div className="framer-1q2copr" data-framer-name="Your digital" >
                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}</span></h2>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-1t38e76 hidden-1fdb8e9">
                                <div className="framer-1q2copr" data-framer-name="Your digital" >
                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="framer-i88od4"
                            >
                            <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM"> {context.text}</p>
                        </div>
                    </motion.div>
                    <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'} ref={ref} whileInView="visible" custom={1} variants={imgAnimation} className="framer-r1gfd9" data-framer-name="Team Blocks Wrap" >
                        {context.cards.map((item) => (
                            <React.Fragment key={item.img}>
                                <div className="framer-cxzg9j" data-framer-name="Team Blocks">
                                    <div className="framer-1dweb51" data-framer-name="Image">
                                        <div data-framer-background-image-wrapper="true"><img src={item.img} alt=""
                                            loading="lazy" /></div>
                                    </div>
                                    <div className="framer-1dw3xah" data-framer-name="Team Blocks">
                                        <div className="framer-15x1yip" data-framer-name="James Flynn" >
                                            <h3 className="framer-text framer-styles-preset-12s9psp" data-styles-preset="kCl4wtJM7"><span data-text-fill="true" className="framer-text">{item.title} </span></h3>
                                        </div>
                                        <div className="framer-1au4ihw" data-framer-name="Team Blocks">
                                            <div className="framer-xkuahq" data-framer-name="Team Blocks">
                                                <div className="framer-o8ykqo" data-framer-name="Image">
                                                    <div data-framer-component-type="SVG" data-framer-name="Image" className="framer-u2xvrq">
                                                        <div className="svgContainer" >  <div id="container_svg1490699482_2022">
                                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1490699482_2022">
                                                                <path d="M6 13.3628C3.24 13.3628 1 15.5928 1 18.3628C1 21.1328 3.24 23.3628 6 23.3628C8.76 23.3628 11 21.1228 11 18.3628C11 15.6028 8.77 13.3628 6 13.3628ZM3.95999 16.3228H6C6.38 16.3228 6.67999 16.6328 6.67999 17.0028C6.67999 17.3728 6.37 17.6828 6 17.6828H3.95999C3.57999 17.6828 3.28 17.3728 3.28 17.0028C3.28 16.6328 3.57999 16.3228 3.95999 16.3228ZM8.04001 20.4028H3.95001C3.57001 20.4028 3.26999 20.0928 3.26999 19.7228C3.26999 19.3528 3.58001 19.0428 3.95001 19.0428H8.04001C8.42001 19.0428 8.72 19.3528 8.72 19.7228C8.72 20.0928 8.42001 20.4028 8.04001 20.4028Z" fill="white"></path>
                                                                <path d="M7.16096 11.4594L7.15913 11.4591C5.75399 11.2338 4.40748 11.4265 3.22081 11.9442C2.85722 12.1011 2.5 11.8472 2.5 11.5028V8.36279C2.5 6.70809 2.97026 5.60845 3.71523 4.91669C4.4669 4.2187 5.57665 3.86279 7 3.86279H17C18.4233 3.86279 19.5329 4.21869 20.2845 4.91768C21.0295 5.61053 21.5 6.71268 21.5 8.37279V15.3728C21.5 17.0275 21.0297 18.1271 20.2848 18.8189C19.5331 19.5169 18.4234 19.8728 17 19.8728H13.43C13.1271 19.8728 12.8989 19.6048 12.9353 19.331C13.0432 18.5641 13.0209 17.7683 12.8605 16.956L12.8605 16.956L12.8599 16.9529C12.2887 14.1507 9.98685 11.9232 7.16096 11.4594Z" fill="url(#svg1490699482_2022_paint0_linear_116_1808)" fillOpacity="0.3" stroke="white"></path>
                                                                <path d="M12.8756 9.47824C12.3097 9.47824 11.7371 9.30308 11.2991 8.94602L9.19046 7.26177C8.97488 7.08661 8.93446 6.76996 9.10962 6.55438C9.28478 6.33879 9.60141 6.29838 9.817 6.47354L11.9257 8.15779C12.4377 8.56875 13.3068 8.56875 13.8188 8.15779L15.9275 6.47354C16.1431 6.29838 16.4664 6.33206 16.6349 6.55438C16.81 6.76996 16.7763 7.09334 16.554 7.26177L14.4453 8.94602C14.0142 9.30308 13.4415 9.47824 12.8756 9.47824Z" fill="white"></path>
                                                                <defs>
                                                                    <linearGradient id="svg1490699482_2022_paint0_linear_116_1808" x1="9" y1="21.0015" x2="9" y2="4.00146" gradientUnits="userSpaceOnUse">
                                                                        <stop stopColor="white"></stop>
                                                                        <stop offset="1" stopColor="white" stopOpacity="0"></stop>
                                                                    </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </div></div>
                                                    </div>
                                                </div>
                                                <div className="framer-ouqkqx" data-framer-name="pixelstemplate@framer.com" >
                                                    <p
                                                        className="framer-text">{item.adress}</p>
                                                </div>
                                            </div>
                                            <div className="framer-16phgcw" data-framer-name="Team Blocks">
                                                <div className="framer-jsdva6" data-framer-name="Image">
                                                    <div data-framer-component-type="SVG" data-framer-name="Image" className="framer-cgpehi">
                                                        <div className="svgContainer" > <div id="container_svg1731737347_3533">
                                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1731737347_3533">
                                                                <path d="M17.62 11.1129C17.19 11.1129 16.85 10.7629 16.85 10.3429C16.85 9.97289 16.48 9.20289 15.86 8.53289C15.25 7.88289 14.58 7.50289 14.02 7.50289C13.59 7.50289 13.25 7.15289 13.25 6.73289C13.25 6.31289 13.6 5.96289 14.02 5.96289C15.02 5.96289 16.07 6.50289 16.99 7.47289C17.85 8.38289 18.4 9.51289 18.4 10.3329C18.4 10.7629 18.05 11.1129 17.62 11.1129Z" fill="white"></path>
                                                                <path d="M21.2298 11.1128C20.7998 11.1128 20.4598 10.7628 20.4598 10.3428C20.4598 6.79279 17.5698 3.91279 14.0298 3.91279C13.5998 3.91279 13.2598 3.56279 13.2598 3.14279C13.2598 2.72279 13.5998 2.36279 14.0198 2.36279C18.4198 2.36279 21.9998 5.94279 21.9998 10.3428C21.9998 10.7628 21.6498 11.1128 21.2298 11.1128Z" fill="white"></path>
                                                                <path d="M2.82424 5.15849L2.82425 5.15849L2.82528 5.15586C3.0367 4.61849 3.37327 4.11917 3.85311 3.66649L3.85319 3.66657L3.86076 3.65912C4.42529 3.10341 5.00458 2.86279 5.59 2.86279C5.79998 2.86279 6.00543 2.90801 6.18363 2.99355L6.18361 2.99361L6.19047 2.99677C6.37619 3.08249 6.53388 3.2068 6.6589 3.3874L6.65888 3.38742L6.66221 3.39211L8.98221 6.66211L8.9822 6.66212L8.98423 6.66495C9.14612 6.88979 9.25497 7.08549 9.32723 7.26211L9.32716 7.26214L9.33043 7.26975C9.40094 7.43427 9.43 7.57663 9.43 7.68279C9.43 7.82215 9.39009 7.97315 9.2929 8.13282L9.2887 8.13973L9.28472 8.14676C9.18264 8.32736 9.02379 8.53189 8.80645 8.74924L8.80638 8.74917L8.79967 8.75615L8.04255 9.54315C7.83378 9.75396 7.74 10.0133 7.74 10.2928C7.74 10.4202 7.75672 10.5312 7.78493 10.6441L7.79184 10.6717L7.80184 10.6984C7.81947 10.7454 7.83716 10.7871 7.84971 10.8167C7.85095 10.8196 7.85213 10.8224 7.85327 10.8251C7.86758 10.8589 7.87269 10.872 7.87566 10.8809L7.88974 10.9231L7.91105 10.9622C8.11468 11.3355 8.44946 11.7953 8.89831 12.3258L8.89828 12.3258L8.90192 12.33C9.357 12.8559 9.84415 13.3939 10.3731 13.933L10.373 13.9331L10.3815 13.9413C10.6071 14.1607 10.8343 14.3815 11.0627 14.593L8.50307 17.1526C8.27247 16.9394 8.04622 16.7219 7.82432 16.5C6.81213 15.4779 5.89921 14.4078 5.08507 13.2897C4.28323 12.1748 3.64338 11.0675 3.1805 9.97802C2.72177 8.8883 2.5 7.86457 2.5 6.90279C2.5 6.28205 2.60943 5.69552 2.82424 5.15849Z" fill="url(#svg1731737347_3533_paint0_linear_116_1815)" fillOpacity="0.5" stroke="white"></path>
                                                                <path d="M21.9696 18.6928C21.9696 18.9728 21.9196 19.2628 21.8196 19.5428C21.7896 19.6228 21.7596 19.7028 21.7196 19.7828C21.5496 20.1428 21.3296 20.4828 21.0396 20.8028C20.5496 21.3428 20.0096 21.7328 19.3996 21.9828C19.3896 21.9828 19.3796 21.9928 19.3696 21.9928C18.7796 22.2328 18.1396 22.3628 17.4496 22.3628C16.4296 22.3628 15.3396 22.1228 14.1896 21.6328C13.0396 21.1428 11.8896 20.4828 10.7496 19.6528C10.3596 19.3628 9.96961 19.0728 9.59961 18.7628L12.8696 15.4928C13.1496 15.7028 13.3996 15.8628 13.6096 15.9728C13.6596 15.9928 13.7196 16.0228 13.7896 16.0528C13.8696 16.0828 13.9496 16.0928 14.0396 16.0928C14.2096 16.0928 14.3396 16.0328 14.4496 15.9228L15.2096 15.1728C15.4596 14.9228 15.6996 14.7328 15.9296 14.6128C16.1596 14.4728 16.3896 14.4028 16.6396 14.4028C16.8296 14.4028 17.0296 14.4428 17.2496 14.5328C17.4696 14.6228 17.6996 14.7528 17.9496 14.9228L21.2596 17.2728C21.5196 17.4528 21.6996 17.6628 21.8096 17.9128C21.9096 18.1628 21.9696 18.4128 21.9696 18.6928Z" fill="white"></path>
                                                                <defs>
                                                                    <linearGradient id="svg1731737347_3533_paint0_linear_116_1815" x1="14.5" y1="3.50147" x2="3.5" y2="13.5015" gradientUnits="userSpaceOnUse">
                                                                        <stop stopColor="white"></stop>
                                                                        <stop offset="1" stopColor="white" stopOpacity="0"></stop>
                                                                    </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </div></div>
                                                    </div>
                                                </div>
                                                <div className="framer-1yq0igo" data-framer-name="+2 9024 234235 (340)" >
                                                    <p
                                                        className="framer-text">{item.phone}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </motion.div>
                </div>
            </div>
        </section>
    )
}

export default Members