import "./index.scss"
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import PNG from "./solCode.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCircleExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal({icon, message, onHide, closeButton, ...props}: any) {
  return (
    <Modal
    {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton closeVariant='white'>
        <Modal.Title id="contained-modal-title-vcenter">
            {icon}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{message}</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="dark">{closeButton}</Button>
      </Modal.Footer>
    </Modal>
  );
}

const textAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: (custom: any) => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.2, duration: 1 },
    }),
};
const imageAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: (custom: any) => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.2, duration: 1 },
    }),
};
const Contact = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const topics = [ "product price", "consultation request", "other"];
    const [currentTopic, setCurrentTopic] = useState(0);
    const [context, setContext] = useState({
        title_highlight: "", title_top: "", title: "", note: "", text: "", button: "", paragraph: [""], name: {
            title: "",
            placeholder: ""
        },
        email: {
            title: "",
            placeholder: ""
        },
        message: {
            title: "",
            placeholder: ""
        },
        subject: {
            title: "",
            options: [""]
        },
        response: { error: "", success: "", info: "", button: ""}

    }
    );

    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let translate = await import(`./languages/${language}`);
            setContext(translate);
        };
        fetchData();
    }, [language]);




    const [aboutUser, setAboutUser] = useState({
        name: "",
        email: "",
        subject: topics[0],
        message: ""
    })
    const onChange = (e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
        | React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === "name") {
            setAboutUser({ ...aboutUser, name: value });
        }
        if (name === "email") {
            setAboutUser({ ...aboutUser, email: value });
        }
        if (name === "subject") {
            setCurrentTopic(parseInt(value));
            setAboutUser({ ...aboutUser, subject: topics[parseInt(value)]});
        }
        if (name === "message") {
            setAboutUser({ ...aboutUser, message: value });
        }

    }
    const [message, setMessage] = useState({
        state: false,
        status: 100,
        text: "Спасибо за ваше сообщение! Мы свяжемся с вами в ближайшее время."
    })
    const onCLick = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent the default form submission behavior
        if(aboutUser.name === "" || aboutUser.email === "" || aboutUser.message === "" || aboutUser.subject === ""){
            setMessage({ state: true, status: 100, text: context.response.info })
            return
        }
        const response = await fetch('https://ubohtcagf7.execute-api.ap-northeast-2.amazonaws.com/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(aboutUser),
        });
        if (response.status === 200) {
            setMessage({ state: true, status: 200, text: context.response.success })
        } else {
            setMessage({ state: true, status: 500, text: context.response.error })
        }
    }

    const selectIcon = (status: number) => {
        switch (status) {
            case 200:
                return <FontAwesomeIcon icon={faCircleCheck} className="icon"/>
            case 500:
                return <FontAwesomeIcon icon={faCircleExclamation} className="icon"/>
            case 100:
                return <FontAwesomeIcon icon={faCircleInfo} className="icon"/>
            default:
                return ""
        }
    }

    return (
        <section className="framer-1r97wfd"  >
            <div className="framer-12no29k" id="contact">
                <div className="framer-1urlfsk"  >
                    <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'}
                        ref={ref} whileInView="visible" custom={1} variants={imageAnimation} className="framer-jzz0h2"  >
                        <div className="framer-1tybo1s"  ></div>
                        <div className="framer-12yh7j2"  >
                            <div data-framer-background-image-wrapper="true"><img src={PNG} alt="" loading="lazy" /></div>
                        </div>
                    </motion.div>
                    <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'}
                        ref={ref} whileInView="visible" custom={1} variants={textAnimation} className="framer-dzdjvj"  >
                        <div className="framer-mrehk0"  >
                            <div className="framer-1ho5ydl hidden-18ccrvo hidden-p5w47l"  >
                                <div className="framer-mbx9bf" >
                                    <p className="framer-text"><span
                                        className="framer-text">{context.note}</span></p>
                                </div>
                                <div data-framer-component-type="SVG" className="framer-kqtwxw">
                                    <div className="svgContainer" >    <div id="container_svg1503322473_433">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1503322473_433">
                                            <line y1="1.8291" x2="449" y2="1.8291" stroke="url(#svg1503322473_433_paint0_linear_22_5947)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg1503322473_433_paint0_linear_22_5947" x1="0" y1="2.8291" x2="619" y2="2.8291" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                            </div>
                            <div className="framer-1a1pvl9"  >
                                <div className="framer-10opef8"  >
                                    <div className="framer-1gpy6tb-container" >
                                        <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title_highlight}</span></h2>
                                    </div>
                                    <div className="framer-2taaz7" >
                                        <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title_top}</span></h2>
                                    </div>
                                </div>

                            </div>
                            <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                                <div className="framer-1vjfh25"
                                >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                <div className="framer-1vjfh25"
                                >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                                <div className="framer-1vjfh25"
                                >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                </div>
                            </div>
                            <div className="framer-1huo939 hidden-72rtr7"  >
                                <div data-framer-component-type="SVG" className="framer-1l6d1of">
                                    <div className="svgContainer" >  <div id="container_svg599100874_424">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg599100874_424">
                                            <line x1="449" y1="1.5" y2="1.5" stroke="url(#svg599100874_424_paint0_linear_22_5286)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg599100874_424_paint0_linear_22_5286" x1="449" y1="0.5" x2="-170" y2="0.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                                <div className="framer-d174w6" >
                                    <p
                                        className="framer-text">{context.note}</p>
                                </div>
                                <div data-framer-component-type="SVG" className="framer-6md674">
                                    <div className="svgContainer" >    <div id="container_svg1312763725_421">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1312763725_421">
                                            <line y1="1.5" x2="449" y2="1.5" stroke="url(#svg1312763725_421_paint0_linear_22_5288)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg1312763725_421_paint0_linear_22_5288" x1="0" y1="2.5" x2="619" y2="2.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={onCLick} >
                            <div className="framer-1tehynk" >
                                <div className="framer-ulnt9v" >
                                    <div className="framer-1n8goct"  >
                                        <div className="ssr-variant hidden-p5w47l">
                                            <div className="framer-1la0vsm" >
                                                <div className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >
                                                    <div className="input_box" >
                                                        <label htmlFor="name">
                                                            <span className="input_title" >{context.name.title}</span>
                                                            <input type="text" id="name" name="name" className="input" value={aboutUser.name} onChange={onChange} placeholder={context.name.placeholder} required={true} tabIndex={1} autoFocus={true} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                            <div className="framer-1la0vsm" >
                                                <div className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >
                                                    <div className="input_box" >
                                                        <label htmlFor="name">
                                                            <span className="input_title" >{context.name.title}</span>
                                                            <input type="text" id="name" name="name" className="input" value={aboutUser.name} onChange={onChange} placeholder={context.name.placeholder} required={true} tabIndex={1} autoFocus={true} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-framer-component-type="SVG" className="framer-1hincbq hidden-p5w47l">
                                        <div className="svgContainer" >  <div id="container_svg1257096732_433">
                                            <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1257096732_433">
                                                <line y1="1.3291" x2="449" y2="1.3291" stroke="url(#svg1257096732_433_paint0_linear_22_5963)" strokeOpacity="0.25" stopColor="2"></line>
                                                <defs>
                                                    <linearGradient id="svg1257096732_433_paint0_linear_22_5963" x1="0" y1="2.3291" x2="619" y2="2.3291" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="white"></stop>
                                                        <stop offset="1" stopOpacity="0"></stop>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div></div>
                                    </div>
                                </div>
                                <div className="framer-ulnt9v" >
                                    <div className="framer-1n8goct"  >
                                        <div className="ssr-variant hidden-p5w47l">
                                            <div className="framer-1la0vsm" >
                                                <div className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >
                                                    <div className="input_box" >
                                                        <label htmlFor="email">
                                                            <span className="input_title" >{context.email.title}</span>
                                                            <input type="email" id="email" className="input" name="email" value={aboutUser.email} onChange={onChange} placeholder={context.email.placeholder} tabIndex={2} required={true} />
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                            <div className="framer-1la0vsm" >
                                                <div className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >
                                                    <div className="input_box" >
                                                        <label htmlFor="email">
                                                            <span className="input_title" >{context.email.title}</span>
                                                            <input type="email" id="email" className="input" name="email" value={aboutUser.email} onChange={onChange} placeholder={context.email.placeholder} tabIndex={2} required={true} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-framer-component-type="SVG" className="framer-1hincbq hidden-p5w47l">
                                        <div className="svgContainer" >  <div id="container_svg1257096732_433">
                                            <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1257096732_433">
                                                <line y1="1.3291" x2="449" y2="1.3291" stroke="url(#svg1257096732_433_paint0_linear_22_5963)" strokeOpacity="0.25" stopColor="2"></line>
                                                <defs>
                                                    <linearGradient id="svg1257096732_433_paint0_linear_22_5963" x1="0" y1="2.3291" x2="619" y2="2.3291" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="white"></stop>
                                                        <stop offset="1" stopOpacity="0"></stop>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div></div>
                                    </div>
                                </div>
                                <div className="framer-ulnt9v" >
                                    <div className="framer-1n8goct"  >
                                        <div className="ssr-variant hidden-p5w47l">
                                            <div className="framer-1la0vsm" >
                                                <div className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >
                                                    <div className="input_box" >
                                                        <label htmlFor="subject">
                                                            <span className="input_title">{context.subject.title} </span>
                                                            <select id="subject" name="subject" className="select input" tabIndex={4} value={currentTopic} onChange={onChange}>
                                                                {context.subject.options.map((item, index) => (
                                                                    <option value={index} key={item}>{item}</option>
                                                                ))}
                                                            </select>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                            <div className="framer-1la0vsm" >
                                                <div className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >
                                                    <div className="input_box" >
                                                        <label htmlFor="subject">
                                                            <span className="input_title">{context.subject.title} </span>
                                                            <select id="subject" name="subject" className="select input" tabIndex={4} value={currentTopic} onChange={onChange}>
                                                                {context.subject.options.map((item, index) => (
                                                                    <option value={index} key={item}>{item}</option>
                                                                ))}
                                                            </select>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-framer-component-type="SVG" className="framer-1hincbq hidden-p5w47l">
                                        <div className="svgContainer" >  <div id="container_svg1257096732_433">
                                            <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1257096732_433">
                                                <line y1="1.3291" x2="449" y2="1.3291" stroke="url(#svg1257096732_433_paint0_linear_22_5963)" strokeOpacity="0.25" stopColor="2"></line>
                                                <defs>
                                                    <linearGradient id="svg1257096732_433_paint0_linear_22_5963" x1="0" y1="2.3291" x2="619" y2="2.3291" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="white"></stop>
                                                        <stop offset="1" stopOpacity="0"></stop>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div></div>
                                    </div>
                                </div>
                                <div className="framer-ulnt9v" >
                                    <div className="framer-1n8goct"  >
                                        <div className="ssr-variant hidden-p5w47l">
                                            <div className="framer-1la0vsm" >
                                                <div className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >
                                                    <div className="input_box" >
                                                        <label htmlFor="message">
                                                            <span className="input_title">{context.message.title}</span>
                                                            <textarea id="message" name="message" className="input text" placeholder={context.message.placeholder} tabIndex={5} value={aboutUser.message} onChange={onChange} required={true}></textarea>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                            <div className="framer-1la0vsm" >
                                                <div className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >
                                                    <div className="input_box" >
                                                        <label htmlFor="message">
                                                            <span className="input_title">{context.message.title}</span>
                                                            <textarea id="message" name="message" className="input text" placeholder={context.message.placeholder} tabIndex={5} value={aboutUser.message} onChange={onChange} required={true}></textarea>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-framer-component-type="SVG" className="framer-1hincbq hidden-p5w47l">
                                        <div className="svgContainer" >  <div id="container_svg1257096732_433">
                                            <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1257096732_433">
                                                <line y1="1.3291" x2="449" y2="1.3291" stroke="url(#svg1257096732_433_paint0_linear_22_5963)" strokeOpacity="0.25" stopColor="2"></line>
                                                <defs>
                                                    <linearGradient id="svg1257096732_433_paint0_linear_22_5963" x1="0" y1="2.3291" x2="619" y2="2.3291" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="white"></stop>
                                                        <stop offset="1" stopOpacity="0"></stop>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="framer-o9uaah"   >
                                <div className="framer-3bj60h-container">
                                    <button type="submit" className="framer-oyI4k framer-v-693ydj" tabIndex={6}>
                                        <div className="framer-693ydj framer-mesh6j" data-border="true" data-highlight="true"
                                            tabIndex={6}>
                                            <div className="framer-crfnzq">
                                                <p className="framer-text">{context.button}</p>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </motion.div>
                </div>
            </div>
            {message ?
                // <div className={`modal
                //     ${message.state ? "active_modal" : ""}
                // `}>
                //     <div className="main_box">
                //         {/* <img src={selectIcon(message.status)} className="icon" /> */}
                //         {selectIcon(message.status)}
                //         <p className="framer-text">{message.text}</p>
                //     </div>
                //     <button onClick={()=>setMessage({ state: false, status: 0, text: "" })}>X</button>
                // </div>
                // : ""
                <MyVerticallyCenteredModal
                    show={message.state}
                    onHide={() => setMessage({
                        ...message,
                        state: false
                    })}
                    icon={selectIcon(message.status)}
                    message={message.text}
                    closeButton={context.response.button}
                /> : ""
                }
            
        </section>
    )
}

export default Contact