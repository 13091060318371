import "./index.scss"
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
const textAnimation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: (custom: any) => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.2, duration: 1 },
    }),
};

const Introduction = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const [context, setContext] = useState({
        powerful: "", title: "", note: "", text: "", app: { title: "", text: "" },
        blockchain: { title: "", text: "" }, mLOps: { title: "", text: "" },
        solutions: { note: "", title: "", text: "" }, business: { title: "", text: "" }
    });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);

    const scrollIntoView = (e: any, elementId: string) => {
        // prevent default behavior
        e.preventDefault();
        const element = document.getElementById(elementId);
        if (element) element.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <section className="framer-13ew9r8" id="introduction" >
            <div className="framer-1un2ayx"  >
                <div className="framer-11nc1m0"  >
                    <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'}
                        ref={ref} whileInView="visible" custom={1} variants={textAnimation} className="framer-3ssmbw"   >
                        <div className="framer-3mzl39"  >
                            <div data-framer-component-type="SVG" className="framer-7rez0">
                                <div className="svgContainer" >    <div id="container_svg599100874_424">
                                    <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg599100874_424">
                                        <line x1="449" y1="1.5" y2="1.5" stroke="url(#svg599100874_424_paint0_linear_22_5286)" strokeOpacity="0.35" stopColor="2"></line>
                                        <defs>
                                            <linearGradient id="svg599100874_424_paint0_linear_22_5286" x1="449" y1="0.5" x2="-170" y2="0.5" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="white"></stop>
                                                <stop offset="1" stopOpacity="0"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div></div>
                            </div>
                            <div className="framer-1g56jfn" >
                                <p className="framer-text"><span
                                    className="framer-text">{context.note}</span></p>
                            </div>
                            <div data-framer-component-type="SVG" className="framer-1kc87cu">
                                <div className="svgContainer" > <div id="container_svg1312763725_421">
                                    <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1312763725_421">
                                        <line y1="1.5" x2="449" y2="1.5" stroke="url(#svg1312763725_421_paint0_linear_22_5288)" strokeOpacity="0.35" stopColor="2"></line>
                                        <defs>
                                            <linearGradient id="svg1312763725_421_paint0_linear_22_5288" x1="0" y1="2.5" x2="619" y2="2.5" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="white"></stop>
                                                <stop offset="1" stopOpacity="0"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div></div>
                            </div>
                        </div>

                        <div className="framer-1b3swhe"  >
                            <div className="framer-73f844-container" >
                                <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                                    <h1 >{context.powerful}</h1>
                                </div>
                                <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                    <h1 >{context.powerful}</h1>
                                </div>
                                <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                                    <h1 >{context.powerful}</h1>
                                </div>
                            </div>

                            <div className="framer-1da989o" >
                                <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}
                                </span></h2>
                            </div>
                        </div>

                        <div className="ssr-variant hidden-p5w47l">
                            <div className="framer-1m4ycra"
                                >
                                <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM"> {context.text}</p>
                            </div>
                        </div>

                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                            <div className="framer-1m4ycra"
                                >
                                <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM">{context.text}</p>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div initial="hidden" animate={inView ? 'visible' : 'hidden'}
                        ref={ref} whileInView="visible" custom={2} variants={textAnimation} className="framer-1aae7r3"   >
                        <div className="framer-qsls2j"  >
                            <div className="framer-je95qz" data-border="true"  >

                                <div className="framer-49is85"  >
                                    <div className="framer-1s3sbvi"  >
                                        <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/4wfk4WPuq8I4oKLKcUaVkyZ20.png" alt=""
                                            loading="lazy" /></div>
                                    </div>
                                </div>

                                <div className="framer-vpdmo1"  >
                                    <div className="framer-lti0vz" >
                                        <h3 className="framer-text framer-styles-preset-12s9psp" data-styles-preset="kCl4wtJM7"><span data-text-fill="true" className="framer-text">{context.app.title}</span></h3>
                                    </div>
                                    <div className="framer-14bkxj8" data-framer-name=""
                                        >
                                        <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM">{context.app.text}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="framer-smc5ba" data-border="true"  >
                                <div className="framer-vw6whp"  >
                                    <div className="framer-151rzvw" >
                                        <h3 className="framer-text framer-styles-preset-12s9psp" data-styles-preset="kCl4wtJM7"><span data-text-fill="true" className="framer-text">{context.blockchain.title}</span></h3>
                                    </div>
                                    <div className="framer-1712gxo"
                                        >
                                        <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM">{context.blockchain.text}</p>
                                    </div>
                                </div>
                                <div className="framer-1v1z9j"  >
                                    <div className="framer-nckmto"  >
                                        <div data-framer-background-image-wrapper="true"> <img src="https://framerusercontent.com/images/FnVoQOOJw0UG4W7aEj1NiRYLvc.png" alt="" srcSet="https://framerusercontent.com/images/FnVoQOOJw0UG4W7aEj1NiRYLvc.png?scale-down-to=512 422w, https://framerusercontent.com/images/FnVoQOOJw0UG4W7aEj1NiRYLvc.png 820w"
                                            sizes="(min-width: 1440px) 440px, (min-width: 810px) and (max-width: 1439px) 440px, (max-width: 809px) 440px"
                                            loading="lazy" data-framer-original-sizes="440px" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="framer-1lfr6ou" data-border="true"  >

                                <div className="framer-1n5y460"  >
                                    <div className="framer-bpufrc" >
                                        <h3 className="framer-text framer-styles-preset-12s9psp" data-styles-preset="kCl4wtJM7"><span data-text-fill="true" className="framer-text">{context.mLOps.title}</span></h3>
                                    </div>
                                    <div className="framer-bjbc8i"
                                        >
                                        <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM">{context.mLOps.text} </p>
                                    </div>
                                </div>

                                <div className="framer-12n1cz"  >
                                    <div className="framer-ad2f06"  >
                                        <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/CwJFANswijhlw0vMnLGfnzUM2q8.png" alt="" srcSet="https://framerusercontent.com/images/CwJFANswijhlw0vMnLGfnzUM2q8.png?scale-down-to=512 422w, https://framerusercontent.com/images/CwJFANswijhlw0vMnLGfnzUM2q8.png 820w"
                                            sizes="(min-width: 1440px) 490px, (min-width: 810px) and (max-width: 1439px) 490px, (max-width: 809px) 490px"
                                            loading="lazy" data-framer-original-sizes="490px" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="framer-o74vfa"  >

                            <div className="framer-6wkfww" data-border="true"  >

                                <div className="framer-866dgb"  >
                                    <div className="framer-vjylu8" >
                                        <div data-framer-background-image-wrapper="true"> <img src="https://framerusercontent.com/images/IIe3x2wRpCZHdFKPQ4PmvULwkA.png" alt="" srcSet="https://framerusercontent.com/images/IIe3x2wRpCZHdFKPQ4PmvULwkA.png?scale-down-to=512 512w, https://framerusercontent.com/images/IIe3x2wRpCZHdFKPQ4PmvULwkA.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/IIe3x2wRpCZHdFKPQ4PmvULwkA.png 1441w"
                                            sizes="(min-width: 1440px) 1441px, (min-width: 810px) and (max-width: 1439px) 1441px, (max-width: 809px) 1441px"
                                            loading="lazy" data-framer-original-sizes="1441px" /></div>
                                    </div>
                                </div>

                                <div className="framer-1lrlkmo"  >
                                    <div className="framer-r5tvq6" >
                                        <div className="framer-jhmtls" data-border="true"  >
                                            <div data-framer-component-type="SVG" className="framer-1yunemf">
                                                <div className="svgContainer" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 25 24"><use href="#svg1932578656_547"></use></svg></div>
                                            </div>

                                            <div className="ssr-variant hidden-p5w47l">
                                                <div className="framer-1du20j6" >
                                                    <p className="framer-text">{context.solutions.note}</p>
                                                </div>
                                            </div>

                                            <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                                <div className="framer-1du20j6" >
                                                    <p className="framer-text">{context.solutions.note}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                                            <div className="framer-1n3q0o" >
                                                <h3 className="framer-text framer-styles-preset-12s9psp" data-styles-preset="kCl4wtJM7"><span data-text-fill="true" className="framer-text">{context.solutions.title}</span></h3>
                                            </div>
                                        </div>
                                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                            <div className="framer-1n3q0o" >
                                                <h3 className="framer-text framer-styles-preset-12s9psp" data-styles-preset="kCl4wtJM7" ><span data-text-fill="true" className="framer-text">{context.solutions.title}</span></h3>
                                            </div>
                                        </div>
                                        <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                                            <div className="framer-1n3q0o" >
                                                <h3 className="framer-text framer-styles-preset-12s9psp" data-styles-preset="kCl4wtJM7"><span data-text-fill="true" className="framer-text">{context.solutions.title}</span></h3>
                                            </div>
                                        </div>
                                        <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                                            <div className="framer-16w7l19"
                                                >
                                                <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM" >{context.solutions.text} </p>
                                            </div>
                                        </div>

                                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                            <div className="framer-16w7l19"
                                                >
                                                <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM" >{context.solutions.text} </p>
                                            </div>
                                        </div>

                                        <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                                            <div className="framer-16w7l19"
                                                >
                                                <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM" >{context.solutions.text} </p>
                                            </div>
                                        </div>
                                        <div className="framer-25d02p-container">
                                            <div className="framer-oyI4k framer-v-693ydj"
                                                tabIndex={0}>
                                                <a className="framer-693ydj framer-mesh6j" data-border="true" data-highlight="true"
                                                    href="#"
                                                    onClick={(e) => scrollIntoView(e, "contact")}
                                                    tabIndex={0}>
                                                    <div className="framer-crfnzq"
                                                        >
                                                        <p className="framer-text">Get Started</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="framer-1rfv8ym" data-border="true"  >
                                <div className="framer-ws2afn" >
                                    <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/dJYj0clidQ8us1eZhqsrU69fBg.png" alt="" srcSet="https://framerusercontent.com/images/dJYj0clidQ8us1eZhqsrU69fBg.png?scale-down-to=512 512w, https://framerusercontent.com/images/dJYj0clidQ8us1eZhqsrU69fBg.png 608w"
                                        sizes="(min-width: 1440px) 298px, (min-width: 810px) and (max-width: 1439px) 298px, (max-width: 809px) 298px"
                                        loading="lazy" data-framer-original-sizes="298px" /></div>
                                </div>
                                <div className="framer-1j0lv20"  >
                                    <div className="framer-gsr5x1" >
                                        <h3 className="framer-text framer-styles-preset-12s9psp" data-styles-preset="kCl4wtJM7"><span data-text-fill="true" className="framer-text">{context.business.title}</span></h3>
                                    </div>
                                    <div className="framer-15ch4kt"
                                        >
                                        <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM">{context.business.text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>
    )
};

export default Introduction
