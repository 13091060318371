import { useEffect } from "react";
import { Route, Routes, } from "react-router-dom";
import Contact from "./components/homeSections/contact";
import { About, Home } from "./pages";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  )
}

export default App;
