import React from 'react'
import { EighthSection, Clients, Header, Footer, DevelopmentPrinciples, Navbar, FAQ, Introduction, Solutions, DevelopmentProcess, Contact } from '../../components/homeSections'

import './index.scss'

const Home = () => {
    return (
        <div className="framer-body-augiA20Il">
            <div id="main" >
                <div className="framer-97rJI framer-Pyo6Q framer-EjWs1 framer-jUJDS framer-8dioY framer-Adeeo" >
                    <div className="framer-72rtr7" >
                        <Navbar />
                        <Header />
                        <Introduction />
                        <DevelopmentProcess />
                        <DevelopmentPrinciples />
                        <Clients />
                        <Solutions />
                        {/* <EighthSection /> */}
                        <FAQ/>
                        <Contact/>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home