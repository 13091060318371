import React, { useEffect, useState } from 'react'
import "./index.scss"
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
const FifthSection = () => {
    const [context, setContext] = useState({ innovative: "", title: "", note: "", text: "", button: "", });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);
    return (

        <section className="framer-1wdcgtp" data-framer-name="Section" >
            <div className="framer-dvvghe hidden-1t38e76" data-framer-name="Gradient"></div>
            <div className="framer-121wmus hidden-1t38e76" data-framer-name="Gradient"></div>
            <div className="framer-1gpnxl2" data-framer-name="Gradient"></div>
            <div className="framer-sx4hj" data-framer-name="Gradient"></div>
            <div className="framer-wfydv7" data-framer-name="Image Wrap" >
                <div className="ssr-variant hidden-1t38e76 hidden-4fkeid">
                    <div className="framer-1bvrrp6" data-framer-name="Image">
                        <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png" alt="" srcSet="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=512 512w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png 1533w"
                            sizes="(min-width: 1440px) 1220px, (max-width: 809px) 470px, (min-width: 810px) and (max-width: 1439px) 650px"
                            loading="lazy" data-framer-original-sizes="1220px" /></div>
                    </div>
                </div>
                <div className="ssr-variant hidden-4fkeid hidden-1fdb8e9">
                    <div className="framer-1bvrrp6" data-framer-name="Image">
                        <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png" alt="" srcSet="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=512 512w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png 1533w"
                            sizes="(min-width: 1440px) 1220px, (max-width: 809px) 470px, (min-width: 810px) and (max-width: 1439px) 650px"
                            loading="lazy" data-framer-original-sizes="470px" /></div>
                    </div>
                </div>
                <div className="ssr-variant hidden-1t38e76 hidden-1fdb8e9">
                    <div className="framer-1bvrrp6" data-framer-name="Image">
                        <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png" alt="" srcSet="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=512 512w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png 1533w"
                            sizes="(min-width: 1440px) 1220px, (max-width: 809px) 470px, (min-width: 810px) and (max-width: 1439px) 650px"
                            loading="lazy" data-framer-original-sizes="650px" /></div>
                    </div>
                </div>
            </div>
            <div className="ssr-variant hidden-1t38e76 hidden-4fkeid">
                <div className="framer-1t02k4l max" data-framer-name="Image Wrap" >
                    <div className="framer-1eqhihn " data-framer-name="Image">
                        <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png" alt="" srcSet="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=512 512w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png 1533w"
                            sizes="(min-width: 1440px) 1220px, (max-width: 809px) 470px, (min-width: 810px) and (max-width: 1439px) 650px"
                            loading="lazy" data-framer-original-sizes="1220px" /></div>
                    </div>
                </div>
            </div>
            <div className="ssr-variant hidden-4fkeid hidden-1fdb8e9">
                <div className="framer-1t02k4l" data-framer-name="Image Wrap" >
                    <div className="framer-1eqhihn" data-framer-name="Image">
                        <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png" alt="" srcSet="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=512 512w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png 1533w"
                            sizes="(min-width: 1440px) 1220px, (max-width: 809px) 470px, (min-width: 810px) and (max-width: 1439px) 650px"
                            loading="lazy" data-framer-original-sizes="470px" /></div>
                    </div>
                </div>
            </div>
            <div className="ssr-variant hidden-1t38e76 hidden-1fdb8e9">
                <div className="framer-1t02k4l" data-framer-name="Image Wrap" >
                    <div className="framer-1eqhihn" data-framer-name="Image">
                        <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png" alt="" srcSet="https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=512 512w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/tXylm343jrIjkBGIhQX3Wo8zM4.png 1533w"
                            sizes="(min-width: 1440px) 1220px, (max-width: 809px) 470px, (min-width: 810px) and (max-width: 1439px) 650px"
                            loading="lazy" data-framer-original-sizes="650px" /></div>
                    </div>
                </div>
            </div>
            <div className="framer-8dwf5n" data-framer-name="Container">
                <div className="framer-101sprs" data-framer-name="Body">
                    <div className="framer-16dkls3" data-framer-name="Content Wrap" >
                        <div className="framer-1bgv53g" data-framer-name="CTA Headings Wrap">
                            <div data-framer-component-type="SVG" data-framer-name="Logo" className="framer-yv0vdr"></div>
                            <div className="framer-zd2tfq" data-framer-name="Tagline">
                                <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-1bd4imv">
                                    <div className="svgContainer" > <div id="container_svg562808578_442">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg562808578_442">
                                            <line x1="449" y1="1.16016" y2="1.16016" stroke="url(#svg562808578_442_paint0_linear_34_7299)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg562808578_442_paint0_linear_34_7299" x1="449" y1="0.160156" x2="-170" y2="0.160156" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                                <div className="framer-1omsv8q" >
                                    <p className="framer-text">
                                        <span
                                            className="framer-text">{context.note}</span>
                                    </p>
                                </div>
                                <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-15kjguo">
                                    <div className="svgContainer" ><div id="container_svg1921603383_437">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1921603383_437">
                                            <line y1="1.16016" x2="449" y2="1.16016" stroke="url(#svg1921603383_437_paint0_linear_34_7301)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg1921603383_437_paint0_linear_34_7301" x1="0" y1="2.16016" x2="619" y2="2.16016" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                            </div>
                            <div className="framer-sc0hwa" data-framer-name="CTA Headings Wrap">
                                <div className="framer-1j9thc6-container">
                                    <div className="ssr-variant hidden-1t38e76 hidden-4fkeid">
                                        <h1>{context.innovative}</h1>
                                    </div>
                                    <div className="ssr-variant hidden-4fkeid hidden-1fdb8e9">
                                        <h1>{context.innovative}</h1>
                                    </div>
                                    <div className="ssr-variant hidden-1t38e76 hidden-1fdb8e9">
                                        <h1 >{context.innovative}</h1>
                                    </div>
                                </div>
                                <div className="framer-6u4qz" data-framer-name="Your digital" >
                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}</span></h2>
                                </div>
                            </div>
                            <div className="framer-1tjf7iv"
                                >
                                <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM"> {context.text}</p>
                            </div>
                        </div>
                        <div className="framer-1vrh72g" data-framer-name="CTA Taglines">
                            <div className="framer-1gunb97" data-border="true" data-framer-name="CTA Taglines">
                                <div data-framer-component-type="SVG" data-framer-name="Icon" className="framer-bkc8bw">
                                    <div className="svgContainer" >
                                        <div id="container_svg1767487289_1021">
                                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1767487289_1021">
                                                <g clipPath="url(#svg1767487289_1021_clip0_34_7312)">
                                                    <path d="M12.5094 3.14453C5.86977 3.14453 0.46875 8.53454 0.46875 15.1602C0.46875 21.7858 5.85876 27.1758 12.4844 27.1758C19.11 27.1758 24.5 21.7858 24.5 15.1602C24.5 8.53454 19.121 3.14453 12.5094 3.14453ZM13.5107 22.1693H11.5081V18.1641L9.5055 16.1615V14.1589L8.5042 13.1576H5.50029L3.40156 10.9517C4.99864 7.52723 8.48017 5.14714 12.5094 5.14714C12.7167 5.14714 12.9219 5.15414 13.1262 5.16716C12.2471 6.5019 11.5081 7.65039 11.5081 7.65039L13.0101 10.1536L12.0088 11.1549L10.0062 10.1536L8.5042 11.6556L10.0062 13.1576H12.5094L16.5146 15.6608L13.5107 22.1693ZM19.5185 15.1602L18.5172 11.1549L20.9213 9.76714C21.9186 11.3242 22.4964 13.1756 22.4964 15.1602C22.4964 15.6448 22.4613 16.1214 22.3943 16.588L19.5185 15.1602Z" fill="white"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="svg1767487289_1021_clip0_34_7312">
                                                        <rect width="30" height="30" fill="white" transform="translate(0.5 0.160156)"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-2qxiae" data-framer-name="Framer Template" >
                                    <p
                                        className="framer-text">{context.button}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default FifthSection