import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import "./index.scss"
const DevelopmentProcess = () => {
    const [context, setContext] = useState({ title_highlight: "", title_top: "", title: "", note: "", text: "", button: "", paragraph: [""] });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);
    const scrollIntoView = (e: any, elementId: string) => {
        // prevent default behavior
        e.preventDefault();
        const element = document.getElementById(elementId);
        if (element) element.scrollIntoView({ behavior: "smooth" });
    };
    return (
        <section className="framer-kzpjwj" id="third">
            <div className="framer-11udwvy"  >
                <div className="framer-1a2dpgh"  >
                    <div className="framer-1xwcvgu" >
                        <div className="framer-1wfkc1z"  >
                            <div className="framer-1pkz81l hidden-72rtr7"  >
                                <div data-framer-component-type="SVG" className="framer-19w5gd7">
                                    <div className="svgContainer">   <div id="container_svg599100874_424">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg599100874_424">
                                            <line x1="449" y1="1.5" y2="1.5" stroke="url(#svg599100874_424_paint0_linear_22_5286)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg599100874_424_paint0_linear_22_5286" x1="449" y1="0.5" x2="-170" y2="0.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                                <div className="framer-1vhcsox" >
                                    <p
                                        className="framer-text">{context.note}</p>
                                </div>
                                <div data-framer-component-type="SVG" className="framer-13rh0fy">
                                    <div className="svgContainer" >     <div id="container_svg1312763725_421">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1312763725_421">
                                            <line y1="1.5" x2="449" y2="1.5" stroke="url(#svg1312763725_421_paint0_linear_22_5288)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg1312763725_421_paint0_linear_22_5288" x1="0" y1="2.5" x2="619" y2="2.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                            </div>

                            <div className="framer-7t6h8s hidden-18ccrvo hidden-p5w47l"  >
                                <div className="framer-8ag0cn" >
                                    <p
                                        className="framer-text">{context.note}</p>
                                </div>
                                <div data-framer-component-type="SVG" className="framer-1syp31b">
                                    <div className="svgContainer" > <div id="container_svg2102089139_437">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg2102089139_437">
                                            <line y1="1.36133" x2="449" y2="1.36133" stroke="url(#svg2102089139_437_paint0_linear_22_5663)" strokeOpacity="0.35" stopColor="2"></line>
                                            <defs>
                                                <linearGradient id="svg2102089139_437_paint0_linear_22_5663" x1="0" y1="2.36133" x2="619" y2="2.36133" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                            </div>

                            <div className="framer-1n6ov80"  >
                                <div className="framer-fy4ath"  >
                                    <div className="framer-v39bd3-container"  >
                                        <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                                            <h1>{context.title_highlight}</h1>
                                        </div>
                                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                            <h1>{context.title_highlight}</h1>
                                        </div>
                                        <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                                            <h1>{context.title_highlight}</h1>
                                        </div>
                                    </div>
                                    <div className="framer-cn6gtm" >
                                        <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title_top}</span></h2>
                                    </div>
                                </div>
                                <div className="framer-1o31f8i" >
                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}</span></h2>
                                </div>
                            </div>

                            <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                                <div className="framer-o8tt5c"
                                    >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                </div>
                            </div>

                            <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                <div className="framer-o8tt5c"
                                    >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                </div>
                            </div>

                            <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                                <div className="framer-o8tt5c"
                                    >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{context.text}</p>
                                </div>
                            </div>
                        </div>
                        <div className="framer-1rp823r"  >
                            {context.paragraph.map((item) => (
                                <div className="framer-19ah3bk" key={item} >
                                    <div className="framer-u1xhss"  >
                                        <div data-framer-component-type="SVG" className="framer-zoyn32">
                                            <div className="svgContainer" >   <div id="container_svg-1044520446_715">
                                                <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg-1044520446_715">
                                                    <circle cx="10.5" cy="11.3613" r="10.5" fill="url(#svg-1044520446_715_paint0_linear_22_5673)" fillOpacity="0.42"></circle>
                                                    <circle cx="10.5" cy="11.3613" r="10" stroke="white" strokeOpacity="0.12"></circle>
                                                    <line x1="6.43514" y1="11.2338" x2="8.89668" y2="13.6954" stroke="white" stopColor="1.23077"></line>
                                                    <line x1="14.4351" y1="9.02735" x2="8.89668" y2="14.5658" stroke="white" stopColor="1.23077"></line>
                                                    <defs>
                                                        <linearGradient id="svg-1044520446_715_paint0_linear_22_5673" x1="10.5" y1="0.861328" x2="10.5" y2="21.8613" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#D9D9D9"></stop>
                                                            <stop offset="1" stopColor="white" stopOpacity="0.19"></stop>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div></div>
                                        </div>
                                        <div className="ssr-variant hidden-p5w47l">
                                            <div className="framer-qnjpuc" >
                                                <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{item}</p>
                                            </div>
                                        </div>
                                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                                            <div className="framer-qnjpuc" >
                                                <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM" >{item}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-framer-component-type="SVG" className="framer-1tsrlxx hidden-p5w47l">
                                        <div className="svgContainer" >  <div id="container_svg-1225570096_437">
                                            <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg-1225570096_437">
                                                <line y1="1.86133" x2="449" y2="1.86133" stroke="url(#svg-1225570096_437_paint0_linear_22_5679)" strokeOpacity="0.25" stopColor="2"></line>
                                                <defs>
                                                    <linearGradient id="svg-1225570096_437_paint0_linear_22_5679" x1="0" y1="2.86133" x2="619" y2="2.86133" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="white"></stop>
                                                        <stop offset="1" stopOpacity="0"></stop>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="framer-zf6ut8"  >
                            <div className="framer-1ki0x6a-container">
                                <div className="framer-oyI4k framer-v-693ydj" tabIndex={0}>
                                    <a className="framer-693ydj framer-mesh6j" data-border="true" data-highlight="true"
                                        onClick={(e) => scrollIntoView(e, "contact")}
                                        href="#contact" tabIndex={0}>
                                        <div className="framer-crfnzq"
                                            >
                                            <p
                                                className="framer-text">{context.button}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="framer-rgy95z" >
                        <div className="ssr-variant hidden-p5w47l hidden-18ccrvo">
                            <div className="framer-h56obv"  ></div>
                        </div>
                        <div className="ssr-variant hidden-18ccrvo hidden-72rtr7">
                            <div className="framer-h56obv"  ></div>
                        </div>
                        <div className="ssr-variant hidden-p5w47l hidden-72rtr7">
                            <div className="framer-h56obv"  ></div>
                        </div>
                        <div className="framer-elb6p6"  >
                            <div data-framer-background-image-wrapper="true"><img src="https://framerusercontent.com/images/TaAMxlAVYgw0f6igMzakD7wNg.svg" alt="" loading="lazy" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
};

export default DevelopmentProcess
