import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import "./index.scss"
import Support from "./svg/support.svg"
import Analytics from "./svg/analytics.svg"
import Integration from "./svg/integration.svg"
const SecondSection = () => {
    const [context, setContext] = useState({
        unleash: "", title: "", note: "", text: "",
        cards: [{ img: "", title: "", text: "" }],

    });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);
    return (
        <section className="framer-1rxdd4g" data-framer-name="Section" >
            <div className="framer-13rx7v7" data-framer-name="Container">
                <div className="framer-pf7ty7" data-framer-name="Body" >
                    <div className="framer-1wd6miv" data-framer-name="Features Headings Wrap" >
                        <div className="framer-1fkqlne" data-framer-name="Tagline Wrap" >
                            <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-18va552">
                                <div className="svgContainer" > <div id="container_svg1142048594_424">
                                    <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1142048594_424">
                                        <line x1="449" y1="1.5" y2="1.5" stroke="url(#svg1142048594_424_paint0_linear_22_6195)" strokeOpacity="0.35" stopColor="2"></line>
                                        <defs>
                                            <linearGradient id="svg1142048594_424_paint0_linear_22_6195" x1="449" y1="0.5" x2="-170" y2="0.5" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="white"></stop>
                                                <stop offset="1" stopOpacity="0"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div></div>
                            </div>
                            <div className="framer-144i9i1" >
                                <p
                                    className="framer-text">{context.note}</p>
                            </div>
                            <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-d5wszl">
                                <div className="svgContainer" >       <div id="container_svg2056266437_421">
                                    <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg2056266437_421">
                                        <line y1="1.5" x2="449" y2="1.5" stroke="url(#svg2056266437_421_paint0_linear_22_6197)" strokeOpacity="0.35" stopColor="2"></line>
                                        <defs>
                                            <linearGradient id="svg2056266437_421_paint0_linear_22_6197" x1="0" y1="2.5" x2="619" y2="2.5" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="white"></stop>
                                                <stop offset="1" stopOpacity="0"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div></div>
                            </div>
                        </div>
                        <div className="framer-b3qr2b" data-framer-name="Features Headings Wrap">
                            <div className="framer-81d4qq-container" data-framer-name="Heading">
                                <div className="ssr-variant hidden-1t38e76 hidden-4fkeid">
                                    <h1 >{context.unleash}</h1>
                                </div>
                                <div className="ssr-variant hidden-4fkeid hidden-1fdb8e9">
                                    <h1 >{context.unleash}</h1>
                                </div>
                                <div className="ssr-variant hidden-1t38e76 hidden-1fdb8e9">
                                    <h1 >{context.unleash}</h1>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-4fkeid">
                                <div className="framer-24a6rx" data-framer-name="Your digital" >
                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}</span></h2>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-1t38e76 hidden-1fdb8e9">
                                <div className="framer-24a6rx" data-framer-name="Your digital" >
                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="framer-tqod9x"
                            >
                            <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM"> {context.text}</p>
                        </div>
                    </div>
                    <div className="framer-ypdnqf" data-framer-name="Features Blocks Wrap" >
                        <div className="framer-9s1iyu" data-framer-name="Features Blocks Wrap">
                            {context.cards.map((item, index) => (
                                <React.Fragment key={item.title}>
                                    <div className="framer-innttb" data-framer-name="Features Block">
                                        <div data-framer-component-type="SVG" data-framer-name="Icon" className="framer-1d01eib">
                                            <div className="svgContainer" >
                                                <div id="container_svg-1475647439_1699">
                                                    <img src={item.img === "Support" ? Support : item.img === "Analytics" ? Analytics : Integration} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="framer-1hpkppv" data-framer-name="Web Based" >
                                            <h3 className="framer-text framer-styles-preset-12s9psp" data-styles-preset="kCl4wtJM7"><span data-text-fill="true" className="framer-text">{item.title}</span></h3>
                                        </div>
                                        <div className="framer-18hzsxf"
                                            >
                                            <p className="framer-text framer-styles-preset-138ee5y" data-styles-preset="sJ5U8ApnM">{item.text}</p>
                                        </div>
                                    </div>
                                    {context.cards.length - 1 !== index ?
                                        <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-8vd0n0 hidden-4fkeid hidden-1t38e76">
                                            <div className="svgContainer"> <div id="container_svg-1312937522_526">
                                                <svg width="4" height="196" viewBox="-1 -1 4 196" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg-1312937522_526">
                                                    <line x1="1" y1="193.042" x2="1" y2="6.10352e-05" stroke="url(#svg-1312937522_526_paint0_radial_22_6210)" strokeOpacity="0.4" stopColor="2"></line>
                                                    <defs>
                                                        <radialGradient id="svg-1312937522_526_paint0_radial_22_6210" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.99991 82.021) rotate(29.8199) scale(47.2572 9122.6)">
                                                            <stop stopColor="white"></stop>
                                                            <stop offset="1" stopColor="white" stopOpacity="0"></stop>
                                                        </radialGradient>
                                                    </defs>
                                                </svg>
                                            </div></div>
                                        </div>
                                        : ""}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SecondSection