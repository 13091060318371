import React, { useEffect, useState } from 'react';
import Globe from 'globe.gl';
import "./index.scss"
import Phone from "./svg/phone.svg"
import Message from "./svg/message.svg"
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
const ThirdSection = () => {
    useEffect(() => {
        const N = 20;
        const arcsData = [...Array(N).keys()].map(() => ({
            startLat: (Math.random() - 0.5) * 180,
            startLng: (Math.random() - 0.5) * 360,
            endLat: (Math.random() - 0.5) * 180,
            endLng: (Math.random() - 0.5) * 360,
            color: [['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)], ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)]]
        }));

        const globe = Globe()
            .globeImageUrl('//unpkg.com/three-globe/example/img/earth-night.jpg')
            .arcsData(arcsData)
            .arcColor('color')
            .arcDashLength(() => Math.random())
            .arcDashGap(() => Math.random())
            .arcDashAnimateTime(() => Math.random() * 4000 + 500)
            .backgroundColor("#02041D");
        const globeViz = document.getElementById('globeViz');
        if (globeViz) {
            globe(globeViz);
        }
    }, []);
    const [context, setContext] = useState({
        empowering: "", title_top: "", title: "", text: "", note: "", button: "",
        members: {
            first: { title: "", email: "", phone: "" },
            second: { title: "", email: "", phone: "" },
            third: { title: "", email: "", phone: "" }
        }
    });
    const { language } = useSelector((state: RootState) => state.language);
    useEffect(() => {
        const fetchData = async () => {
            let header = await import(`./languages/${language}`);
            setContext(header);
        };
        fetchData();
    }, [language]);
    return (
        <section className="framer-61j3w9" data-framer-name="Section">
            <div className="framer-1q9737y" data-framer-name="Container">
                <div className="framer-1kaoxzn" data-framer-name="Body">
                    <div id="globeViz" className='glob' ></div>;
                    <div className="framer-nro0f5" data-framer-name="Feature Details Wrapper">
                        <div className="framer-s2ewvg" data-framer-name="Feature Details Wrapper">
                            <div className="framer-cwpdvo hidden-4fkeid hidden-1t38e76" data-framer-name="Tagline" >
                                <div className="framer-851z3w" data-framer-name="How we do" >
                                    <p
                                        className="framer-text">How we do</p>
                                </div>
                                <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-1xwyue6">
                                    <div className="svgContainer">   <div id="container_svg1503322473_433">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1503322473_433">
                                            <line y1="1.8291" x2="449" y2="1.8291" stroke="url(#svg1503322473_433_paint0_linear_22_5947)" strokeOpacity="0.35" strokeWidth="2"></line>
                                            <defs>
                                                <linearGradient id="svg1503322473_433_paint0_linear_22_5947" x1="0" y1="2.8291" x2="619" y2="2.8291" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                            </div>
                            <div className="framer-11n9tyi" data-framer-name="Feature Headings Wrapper">
                                <div className="framer-12uktrn" data-framer-name="Feature Headings Wrapper">
                                    <div className="framer-fzbx7m-container" data-framer-name="Heading">
                                        <div className="ssr-variant hidden-1t38e76">
                                            <h1>{context.empowering}</h1>
                                        </div>
                                        <div className="ssr-variant hidden-4fkeid hidden-1fdb8e9">
                                            <h1>{context.empowering}</h1>
                                        </div>
                                    </div>
                                    <div className="framer-1ntinmm" data-framer-name="integrate" >
                                        <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title_top}</span></h2>
                                    </div>
                                </div>
                                <div className="framer-kn9idq" data-framer-name="with all your tools" >
                                    <h2 className="framer-text framer-styles-preset-186siln" data-styles-preset="L6sSH5QlW"><span data-text-fill="true" className="framer-text">{context.title}</span></h2>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-1t38e76 hidden-4fkeid">
                                <div className="framer-1wqvwy" data-framer-name="Create stunning, professional-quality websites in record time with our powerful UI kit. Elevate your SAAS game today!"
                                    >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM">C{context.text}</p>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-4fkeid hidden-1fdb8e9">
                                <div className="framer-1wqvwy" data-framer-name="Create stunning, professional-quality websites in record time with our powerful UI kit. Elevate your SAAS game today!"
                                    >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM">{context.text}</p>
                                </div>
                            </div>
                            <div className="ssr-variant hidden-1t38e76 hidden-1fdb8e9">
                                <div className="framer-1wqvwy" data-framer-name="Create stunning, professional-quality websites in record time with our powerful UI kit. Elevate your SAAS game today!"
                                    >
                                    <p className="framer-text framer-styles-preset-1wzfti9" data-styles-preset="q_SddjwCM">Create stunning, professional-quality websites in record time with our powerful UI kit. Elevate your SAAS game today!</p>
                                </div>
                            </div>
                            <div className="framer-17o45yf hidden-1fdb8e9" data-framer-name="Tagline Wrap">
                                <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-1rsw90i">
                                    <div className="svgContainer" >  <div id="container_svg599100874_424">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg599100874_424">
                                            <line x1="449" y1="1.5" y2="1.5" stroke="url(#svg599100874_424_paint0_linear_22_5286)" strokeOpacity="0.35" strokeWidth="2"></line>
                                            <defs>
                                                <linearGradient id="svg599100874_424_paint0_linear_22_5286" x1="449" y1="0.5" x2="-170" y2="0.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                                <div className="framer-4kcmtl" data-framer-name="How we do" >
                                    <p
                                        className="framer-text">{context.note}</p>
                                </div>
                                <div data-framer-component-type="SVG" data-framer-name="Divider" className="framer-1cvknzf">
                                    <div className="svgContainer" >    <div id="container_svg1312763725_421">
                                        <svg width="451" height="5" viewBox="-1 -1 451 5" fill="none" xmlns="http://www.w3.org/2000/svg" id="svg1312763725_421">
                                            <line y1="1.5" x2="449" y2="1.5" stroke="url(#svg1312763725_421_paint0_linear_22_5288)" strokeOpacity="0.35" strokeWidth="2"></line>
                                            <defs>
                                                <linearGradient id="svg1312763725_421_paint0_linear_22_5288" x1="0" y1="2.5" x2="619" y2="2.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="white"></stop>
                                                    <stop offset="1" stopOpacity="0"></stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div></div>
                                </div>
                            </div>
                        </div>
                        <div className="framer-171rso8">
                            <div className="framer-1l2tehj" data-framer-name="Contact Wrap">
                                <div className="framer-1s6thx0" data-framer-name="Contact Wrap">
                                    <div className="framer-5o6sw6" data-framer-name="Contact Wrap">
                                        <div className="framer-jeyvr2" data-framer-name="San Fransisco, CA" >
                                            <p className="framer-text"><span data-text-fill="true" className="framer-text">{context.members.first.title}</span></p>
                                        </div>
                                        <div className="framer-fgxpt6" data-framer-name="Contact Wrap">
                                            <div className="framer-16vopup" data-framer-name="Contact Wrap">
                                                <div className="framer-9hhfv2" data-framer-name="Icon">
                                                    <div data-framer-component-type="SVG" data-framer-name="Icon" className="framer-fzw30y">
                                                        <div className="svgContainer" >
                                                            <div id="container_svg-225162482_2014">
                                                                <img src={Message} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="framer-jv8ywr" data-framer-name="pixelstemplate@framer.com" >
                                                    <p className="framer-text"><span
                                                        className="framer-text">{context.members.first.email}</span></p>
                                                </div>
                                            </div>
                                            <div className="framer-o6mvud" data-framer-name="Contact Wrap">
                                                <div className="framer-11r78z8" data-framer-name="Icon">
                                                    <div data-framer-component-type="SVG" data-framer-name="Icon" className="framer-6g7k00">
                                                        <div className="svgContainer">
                                                            <div id="container_svg1351179585_3528">
                                                                <img src={Phone} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="framer-12kfj7m" data-framer-name="+2 9024 234235 (340)" >
                                                    <p className="framer-text"><span
                                                        className="framer-text">{context.members.first.phone}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="framer-1y7uznq" data-framer-name="Contact Wrap">
                                    <div className="framer-yfrdu1" data-framer-name="Contact Wrap">
                                        <div className="framer-15okq9t" data-framer-name="San Fransisco, CA" >
                                            <p className="framer-text"><span data-text-fill="true" className="framer-text">{context.members.second.title}</span></p>
                                        </div>
                                        <div className="framer-bx7k81" data-framer-name="Contact Wrap">
                                            <div className="framer-ppismu" data-framer-name="Contact Wrap">
                                                <div className="framer-863ell" data-framer-name="Icon">
                                                    <div data-framer-component-type="SVG" data-framer-name="Icon" className="framer-1d5gj3c">
                                                        <div className="svgContainer" >   <div id="container_svg-225162482_2014">
                                                            <img src={Message} alt="" />
                                                        </div></div>
                                                    </div>
                                                </div>
                                                <div className="framer-1n5ke9g" data-framer-name="pixelstemplate@framer.com" >
                                                    <p className="framer-text"><span
                                                        className="framer-text">{context.members.second.email}</span></p>
                                                </div>
                                            </div>
                                            <div className="framer-1n5slct" data-framer-name="Contact Wrap">
                                                <div className="framer-1c4lsc1" data-framer-name="Icon">
                                                    <div data-framer-component-type="SVG" data-framer-name="Icon" className="framer-1azn9x">
                                                        <div className="svgContainer" >  <div id="container_svg1351179585_3528">
                                                            <img src={Phone} alt="" />
                                                        </div></div>
                                                    </div>
                                                </div>
                                                <div className="framer-7i6xc8" data-framer-name="+2 9024 234235 (340)" >
                                                    <p className="framer-text"><span
                                                        className="framer-text">{context.members.second.phone}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="framer-oarzfo" data-framer-name="Contact Wrap">
                                <div className="framer-1ac0iy6" data-framer-name="Contact Wrap">
                                    <div className="framer-1vsu43a" data-framer-name="Contact Wrap">
                                        <div className="framer-1q8qm9t" data-framer-name="San Fransisco, CA" >
                                            <p className="framer-text"><span data-text-fill="true" className="framer-text">{context.members.third.title}</span></p>
                                        </div>
                                        <div className="framer-g24dxc" data-framer-name="Contact Wrap">
                                            <div className="framer-1o4dgbd" data-framer-name="FraContact Wrapme 71" >
                                                <div className="framer-1do99ys" data-framer-name="Icon">
                                                    <div data-framer-component-type="SVG" data-framer-name="Icon" className="framer-ibjzxr">
                                                        <div className="svgContainer">   <div id="container_svg-225162482_2014">
                                                            <img src={Message} alt="" />
                                                        </div></div>
                                                    </div>
                                                </div>
                                                <div className="framer-x8vk0c" data-framer-name="pixelstemplate@framer.com" >
                                                    <p className="framer-text"><span
                                                        className="framer-text">{context.members.third.email}</span></p>
                                                </div>
                                            </div>
                                            <div className="framer-6kh8ho" data-framer-name="Contact Wrap">
                                                <div className="framer-15lyoiy" data-framer-name="Icon">
                                                    <div data-framer-component-type="SVG" data-framer-name="Icon" className="framer-10qkvrl">
                                                        <div className="svgContainer" >  <div id="container_svg1351179585_3528">
                                                            <img src={Phone} alt="" />
                                                        </div></div>
                                                    </div>
                                                </div>
                                                <div className="framer-1bo80ir" data-framer-name="+2 9024 234235 (340)" >
                                                    <p className="framer-text"><span
                                                        className="framer-text">{context.members.third.phone}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="framer-8lwzvj" data-framer-name="CTA Wrapper" >
                            <div className="framer-1f950g6-container">
                                <div className="framer-oyI4k framer-v-693ydj" tabIndex={0}>
                                    <a className="framer-693ydj framer-mesh6j" data-border="true" data-framer-name="Glass" data-highlight="true"
                                        href="./features" tabIndex={0}>
                                        <div className="framer-crfnzq"
                                            >
                                            <p
                                                className="framer-text">{context.button}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ThirdSection